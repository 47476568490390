import React from 'react'
import { useAppDispatch, useAppSelector } from '../../store/hook'
import { RootState } from '../../store/store'
import Popup from '../common/Popup'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { logoutAction } from '../../store/auth/auth.action'
import { openNotification } from '../../utils/notification'
import { TYPE } from '../../models/common'
import { actions as authActions } from '../../store/auth/auth.reducer'
import { TYPES_AUTH } from '../../store/auth/auth.type'
//icon
import DefaultAvatar from '../../assets/images/default-avt.svg'
import IconSetting from '../../assets/icons/common/setting-icon.svg'
import IconLogout from '../../assets/icons/common/logout-icon.svg'
import IconInfo from '../../assets/icons/common/info-icon.svg'
import IconMail from '../../assets/icons/common/mail-icon.svg'
import classNames from 'classnames'
import { LANGUAGE, PATHNAME } from '@constants/common'
import Button from '@components/common/Button'
import { helper } from '@utils/helper/common'

interface IPropsType {
  open: boolean
  close: () => void
  children: React.ReactNode
  claassNamePopup?: string
}

const MoreAccountSettingPopup: React.FC<IPropsType> = (props) => {
  const { open, close, children, claassNamePopup } = props
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { userProfile } = useAppSelector((state: RootState) => state.profile)

  const handleRedirectToProfileSetting = () => {
    close()
    navigate(PATHNAME.PROFILE_SETTING)
  }

  const logout = async () => {
    try {
      const res = await dispatch(logoutAction()).unwrap()
      if (!res.error) {
        dispatch(authActions[TYPES_AUTH.REDUCERS.SET_CLEAR_DATA]({}))
        return navigate('/')
      } else {
        openNotification({
          type: TYPE.ERROR,
          key: 'logout',
          message: t('notification.somethingBug.titleFirst'),
          description: t('notification.somethingBug.titleSecond'),
        })
      }
    } catch {
      openNotification({
        type: TYPE.ERROR,
        key: 'logout',
        message: t('notification.somethingBug.titleFirst'),
        description: t('notification.somethingBug.titleSecond'),
      })
    }
  }

  const handleRedirectToContact = () => {
    helper.redirectToContact()
  }

  const handleRedirectToHelp = () => {
    const currentLanguage = navigator.language
    if (currentLanguage === LANGUAGE.JA) {
      window.open('https://support.whereness.io/hc/ja')
    } else {
      window.open('https://support.whereness.io/hc/ja')
    }
  }

  return (
    <Popup
      open={open}
      close={close}
      content={
        <div className="flex flex-col">
          <div className="pt-2 px-4">
            <div className="w-16 h-16 rounded-full overflow-hidden mb-3">
              <img
                src={
                  userProfile?.info.avatar
                    ? userProfile?.info.avatar
                    : DefaultAvatar
                }
                className="w-full h-full object-cover"
                alt=""
              />
            </div>
            <div className="text-base font-semibold text-white__op-900 truncate">
              {userProfile?.info.fullName}
            </div>
            <div className="text-sm text-white__op-600 font-lato">
              {userProfile?.info.email}
            </div>
          </div>
          <hr className="border-white__op-100 my-2" />
          <Button.MenuItem
            className="font-lato"
            icon={<img src={IconSetting} alt="" />}
            title={t('common.settings')}
            onClick={handleRedirectToProfileSetting}
          />
          <Button.MenuItem
            className="font-lato"
            icon={<img src={IconLogout} alt="" />}
            title={t('common.logout')}
            onClick={logout}
          />
          <hr className="border-white__op-100 my-2" />
          <Button.MenuItem
            className="font-lato"
            icon={<img src={IconInfo} alt="" />}
            title={t('common.help')}
            onClick={handleRedirectToHelp}
          />
          <Button.MenuItem
            className="font-lato"
            icon={<img src={IconMail} alt="" />}
            title={t('common.contact')}
            onClick={handleRedirectToContact}
          />
        </div>
      }
      claassNamePopup={classNames('!w-[17.5rem] -left-8 mb-4', claassNamePopup)}
      position="top"
    >
      {children}
      {/* <Icon.ButtonIcon
        icon={<img src={arrowDropDownIcon} alt="icon" />}
        customClass="ml-2.5 w-6 h-6"
        onClick={() => setIsOpenSetting(true)}
      /> */}
    </Popup>
  )
}

export default MoreAccountSettingPopup
