import React from 'react'
import classNames from 'classnames'

interface IPropsType {
  isFlexible?: boolean
}
const SpinComponent: React.FC<IPropsType> = (props) => {
  const { isFlexible } = props
  return (
    <div
      className={classNames(
        'inset-0 flex items-center justify-center z-[9999]',
        {
          fixed: !isFlexible,
          absolute: isFlexible,
        }
      )}
    >
      <div className="loading">
        <div className="loading__back">
          <div className="loading__face loading__face--1"></div>
          <div className="loading__face loading__face--2"></div>
          <div className="loading__face loading__face--3"></div>
        </div>
        <div className="loading__front">
          <div className="loading__face loading__face--4"></div>
          <div className="loading__face loading__face--5"></div>
        </div>
      </div>
    </div>
  )
}

export default SpinComponent
