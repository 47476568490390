import React from 'react'
import IconUpload from '../../../assets/icons/sceneSetting/upload.svg'
import { useTranslation } from 'react-i18next'
import Button from '@components/common/Button'

interface PropTypes {
  openFileUploadWindow: () => void
}

const DefaultUploadMedia: React.FC<PropTypes> = ({ openFileUploadWindow }) => {
  const { t } = useTranslation()

  return (
    <>
      <div className="text-sm font-semibold leading-5 px-4 text-white__op-600 mb-7 mt-6">
        {t('popup.mediaLib.titleNoFile')}
      </div>
      <div className="px-4 mb-2.5">
        <Button.Normal
          title={t('popup.mediaLib.btnUpload')}
          icon={<img src={IconUpload} className="mr-3" alt="" />}
          onClick={() => openFileUploadWindow()}
        />
      </div>

      {/* <div className="text-center text-white__op-500 text-xs leading-5 font-normal">
        {t('popup.mediaLib.fileDrop')}
      </div> */}
    </>
  )
}

export default React.memo(DefaultUploadMedia)
