import { gql } from '@apollo/client'

export const CHANGE_PASSWORD = gql`
  mutation changePassword($password: String!, $newPassword: String!) {
    changePassword(
      changePasswordInput: { password: $password, new_password: $newPassword }
    ) {
      statusCode
      message
      error {
        errorCode
        message
      }
    }
  }
`
export const DELETE_ACCOUNT = gql`
  mutation cancelAccount($input: CancelAccountInput!) {
    cancelAccount(input: $input) {
      statusCode
      message
      error {
        errorCode
        message
      }
    }
  }
`
