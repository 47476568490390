import { gql } from '@apollo/client'

export const CREATE_PROJECT = gql`
  mutation createProject(
    $organizationId: String!
    $projectName: String!
    $projectDescription: String
    $langCode: String
  ) {
    createProject(
      organizationId: $organizationId
      createProjectInput: {
        projectName: $projectName
        projectDescription: $projectDescription
        langCode: $langCode
      }
    ) {
      statusCode
      message
      data {
        projectId
        projectName
        langCode
        lastEditedAt
        defaultSceneSet {
          layerId
          spaceId
          sceneId
        }
        projectPreviewUrl
        version
      }
      error {
        message
        errorCode
      }
    }
  }
`

export const UPDATE_PROJECT = gql`
  mutation updateProject(
    $projectId: Int!
    $updateProjectInput: UpdateProjectInput!
  ) {
    updateProject(
      projectId: $projectId
      updateProjectInput: $updateProjectInput
    ) {
      statusCode
      message
      data {
        projectId
        projectName
        langCode
        lastEditedAt
        defaultSceneSet {
          layerId
          spaceId
          sceneId
        }
        projectPreviewUrl
        version
      }
      error {
        errorCode
        message
      }
    }
  }
`

export const DELETE_PROJECT = gql`
  mutation deleteProject($projectId: Int!, $organizationId: String!) {
    deleteProject(
      input: { projectId: $projectId, organizationId: $organizationId }
    ) {
      statusCode
      message
      error {
        errorCode
        message
      }
    }
  }
`

export const UPLOAD_MEDIA_FILE = gql`
  mutation createMediaFile($projectId: Int!, $fileInput: FileUploadMediaInput) {
    createMediaFile(projectId: $projectId, fileInput: $fileInput) {
      statusCode
      message
      data {
        folderId
        resourceId
        url
        key
        thumbnail
        name
        mediaType
        size
        uploadedBy {
          fullName
        }
        createdAt
        currentOrganizationStorage
      }
      error {
        errorCode
        message
      }
    }
  }
`

export const DELETE_MEDIA_FILE = gql`
  mutation deleteResource($input: DeleteResourceInput!) {
    deleteResource(input: $input) {
      statusCode
      message
      data {
        currentOrganizationStorage
        currentProjectStorage
      }
      error {
        errorCode
        message
      }
    }
  }
`

export const UPDATE_MEDIA_FILE = gql`
  mutation ($input: UpdateResourceInput!) {
    updateResource(input: $input) {
      statusCode
      message
      data {
        mediaType
        resourceId
        folderId
        name
        key
      }
      error {
        errorCode
        message
      }
    }
  }
`

export const GET_MEDIA_FOLDERS = gql`
  mutation getMediaFolders($input: GetMediaFoldersInput!) {
    getMediaFolders(input: $input) {
      statusCode
      message
      error {
        errorCode
        message
      }
      data {
        id
        name
      }
    }
  }
`

export const CREATE_MEDIA_FOLDER = gql`
  mutation createMediaFolder($input: CreateMediaFolderInput!) {
    createMediaFolder(input: $input) {
      statusCode
      message
      error {
        errorCode
        message
      }
      data {
        id
        name
      }
    }
  }
`

export const DELETE_MEDIA_FOLDER = gql`
  mutation deleteMediaFolder($input: DeleteMediaFolderInput!) {
    deleteMediaFolder(input: $input) {
      statusCode
      message
      data {
        currentOrganizationStorage
        currentProjectStorage
      }
      error {
        errorCode
        message
      }
    }
  }
`

export const UPDATE_MEDIA_FOLDER = gql`
  mutation updateMediaFolder($input: UpdateMediaFolderInput!) {
    updateMediaFolder(input: $input) {
      statusCode
      message
      error {
        errorCode
        message
      }
      data {
        id
        name
      }
    }
  }
`

export const UPDATE_PROJECT_PUBLISH = gql`
  mutation updateProjectPublish($input: UpdateProjectPublishInput!) {
    updateProjectPublish(input: $input) {
      message
      statusCode
      data {
        projectId
        langCode
        description
        thumbnailId
        thumbnailUrl
        projectPublishUrl
        status
        ticketType
        ticketPrice
      }
      error {
        message
        errorCode
      }
    }
  }
`
