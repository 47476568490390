import { gql } from '@apollo/client'

export const GET_SIGNED_URL_UPLOAD_AVATAR = gql`
  query getSignedUrlUploadAvatar($input: GetSignedUrlUploadAvatarInput!) {
    getSignedUrlUploadAvatar(input: $input) {
      statusCode
      message
      data {
        key
        bucket
        algorithm
        credential
        date
        policy
        signature
        contentType
        url
        expires
        securityToken
      }
      error {
        errorCode
        message
      }
    }
  }
`

export const GET_USER_PROFILE = gql`
  query {
    getUserProfile {
      message
      statusCode
      data {
        info {
          fullName
          avatar
          email
          langCode
          createdAt
          isRegisteredByGoogle
          organizationRemainingDays
          limitedOrganizationStorage
          currentOrganizationStorage
        }
        lastActivities {
          totalProjects
        }
      }
      error {
        errorCode
        message
      }
    }
  }
`

export const GET_STORAGE = gql`
  query {
    getUserProfile {
      message
      statusCode
      data {
        info {
          limitedOrganizationStorage
          currentOrganizationStorage
        }
      }
      error {
        errorCode
        message
      }
    }
  }
`
