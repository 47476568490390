import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import classNames from 'classnames'
import { useAppDispatch, useAppSelector } from '@stores/hook'
import { RootState } from '@stores/store'
import Icon from '@components/common/Icon'
import Select, { IValueSelected } from '@components/common/selectInput/Select'
import {
  availableStreamAction,
  updateProjectPublishAction,
} from '@stores/project/project.action'
import {
  IDateUpdateProjectPublish,
  IRequestUpdateProjectPublish,
  TypePublicProject,
} from '@models/project'
import { openNotification } from '@utils/notification'
import { TYPE, TypeLanguage, TYPE_ERROR } from '@models/common'
// import Input from '@components/common/Input'
import ThumbProjectComponent from './ThumbProjectComponent'
import Button from '@components/common/Button'
import { TYPE_PROJECT } from '@stores/project/project.type'
import { actions as projectActions } from '@stores/project/project.reducer'
//icon
import IconPublic from '@assets/icons/sceneSetting/public.svg'
import IconPrivate from '@assets/icons/sceneSetting/private.svg'
// import IconPublicLimit from '@assets/icons/sceneSetting/publicLimit.svg'
// import IconTicket from '@assets/icons/sceneSetting/ticket.svg'
// import IconMoney from '@assets/icons/sceneSetting/money.svg'
import IconLink from '@assets/icons/sceneSetting/link.svg'
// import IconMember from '@assets/icons/sceneSetting/member.svg'
// import IconCsDomain from '@assets/icons/sceneSetting/cusDomain.svg'
import PopupConfirm from '@components/common/PopupConfirm'
import { helper } from '@utils/helper/common'

interface IPropsType {
  openPopupSuccess: () => void
}

const ProjectSettingComponent: React.FC<IPropsType> = ({
  openPopupSuccess,
}): React.ReactElement => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const optionsPublic = [
    {
      value: TypePublicProject.PRIVATE,
      label: (
        <>
          <div className="w-5 h-5 flex flex-shrink-0 items-center justify-center mr-3">
            <img src={IconPrivate} alt="" />
          </div>
          <p className="w-full truncate select-none text-sm leading-6 text-white__op-900 font-lato text-left">
            {t('projectSetting.select.publish.private')}
          </p>
        </>
      ),
    },
    // {
    //   value: TypePublicProject.LIMIT,
    //   label: (
    //     <>
    //       <div className="w-5 h-5 flex flex-shrink-0 items-center justify-center mr-3">
    //         <img src={IconPublicLimit} alt="" />
    //       </div>
    //       <p className="w-full truncate cursor-default select-none text-sm leading-6 text-white__op-900">
    //         {t('projectSetting.select.publish.limit')}
    //       </p>
    //     </>
    //   ),
    // },
    {
      value: TypePublicProject.PUBLIC,
      label: (
        <>
          <div className="w-5 h-5 flex flex-shrink-0 items-center justify-center mr-3">
            <img src={IconPublic} alt="" />
          </div>
          <p className="w-full truncate select-none text-sm leading-6 text-white__op-900 font-lato text-left">
            {t('projectSetting.select.publish.public')}
          </p>
        </>
      ),
    },
  ]

  // const optionsTicket = [
  //   {
  //     value: TypeTicket.FREE,
  //     label: (
  //       <>
  //         <div className="w-5 h-5 flex flex-shrink-0 items-center justify-center mr-3">
  //           <img src={IconTicket} alt="" />
  //         </div>
  //         <p className="w-full truncate select-none text-sm leading-6 text-white__op-900 font-lato text-left">
  //           {t('projectSetting.select.ticket.free')}
  //         </p>
  //       </>
  //     ),
  //   },
  //   {
  //     value: TypeTicket.PAID,
  //     label: (
  //       <>
  //         <div className="w-5 h-5 flex flex-shrink-0 items-center justify-center mr-3">
  //           <img src={IconTicket} alt="" />
  //         </div>
  //         <p className="w-full truncate select-none text-sm leading-6 text-white__op-900 font-lato text-left">
  //           {t('projectSetting.select.ticket.paid')}
  //         </p>
  //       </>
  //     ),
  //   },
  // ]

  // const optionsLanguage = [
  //   {
  //     value: TypeLanguage.ENLISH,
  //     label: 'English',
  //   },
  //   {
  //     value: TypeLanguage.JAPAN,
  //     label: '日本語',
  //   },
  // ]

  // const optionsMoney = [
  //   {
  //     value: TypeMoney.USD,
  //     label: 'USD',
  //   },
  //   {
  //     value: TypeMoney.JPY,
  //     label: 'JPY',
  //   },
  // ]

  const { projectInfo, isSettingPublish, projectPublishInfo } = useAppSelector(
    (state: RootState) => state.project
  )

  const { user } = useAppSelector((state: RootState) => state.auth)

  const [pjDescription, setPjDescription] = useState<string>(
    projectPublishInfo ? projectPublishInfo.description : ''
  )
  const [typePublic, setTypePublic] = useState<IValueSelected>(
    _.find(
      optionsPublic,
      (type) => type.value === projectPublishInfo?.status
    ) || null
  )
  // const [typeTicket, setTypeTicket] = useState<IValueSelected>({
  //   value: TypeTicket.FREE,
  //   label: (
  //     <>
  //       <div className="w-5 h-5 flex flex-shrink-0 items-center justify-center mr-3">
  //         <img src={IconTicket} alt="" />
  //       </div>
  //       <p className="w-full truncate cursor-pointer select-none text-sm leading-6 text-white__op-900 font-lato text-left">
  //         {t('projectSetting.select.ticket.free')}
  //       </p>
  //     </>
  //   ),
  // })
  // const [typeLanguage, setTypeLanguage] = useState<IValueSelected>({
  //   value: TypeLanguage.ENLISH,
  //   label: 'English',
  // })
  // const [typeMoney, setTypeMoney] = useState<IValueSelected>({
  //   value: TypeMoney.USD,
  //   label: 'USD',
  // })
  const [isOpenSetting, setIsOpenSetting] = useState<boolean>(false)
  const [dataUpdateProject, setDataUpdateProject] =
    useState<IDateUpdateProjectPublish | null>(null)
  const [isUpdate, setIsUpdate] = useState<boolean>(false)
  const [isViewOverLimit, setIsViewOverLimit] = useState<boolean>(false)
  useEffect(() => {
    setPjDescription(projectPublishInfo?.description ?? '')
    setTypePublic(
      _.find(optionsPublic, (x) => x.value === projectPublishInfo?.status) ||
        null
    )
  }, [projectPublishInfo])

  useEffect(() => {
    if (_.isEmpty(dataUpdateProject)) {
      setDataUpdateProject(null)
      setIsUpdate(false)
    }
    if (dataUpdateProject?.status === TypePublicProject.PUBLIC) {
      setIsUpdate(true)
    }
  }, [dataUpdateProject])

  const handleChangePublic = async (value: IValueSelected) => {
    if (projectInfo && value && !Array.isArray(value)) {
      setDataUpdateProject({
        ...dataUpdateProject,
        status: value.value as string,
      })
      setTypePublic(value)
    }
  }

  // const handleChangeTicket = (value: IValueSelected) => {
  //   setTypeTicket(value)
  // }
  // const handleChangeLanguage = (value: IValueSelected) => {
  //   setTypeLanguage(value)
  // }
  // const handleChangeMoney = (value: IValueSelected) => {
  //   setTypeMoney(value)
  // }

  const handleUpdateProjectPublish = async () => {
    if (!projectInfo || !dataUpdateProject || !user) {
      return
    }

    try {
      if (dataUpdateProject.status === TypePublicProject.PUBLIC) {
        const resAvailableStream = await dispatch(
          availableStreamAction()
        ).unwrap()
        if (resAvailableStream.data.availableStream === false) {
          setIsViewOverLimit(true)
          return
        }
      }
    } catch {
      openNotification({
        type: TYPE.ERROR,
        key: 'availableStream',
        message: t('notification.somethingBug.titleFirst'),
        description: t('notification.somethingBug.titleSecond'),
      })
    }

    const { projectId } = projectInfo
    const { organizationId } = user
    const req: IRequestUpdateProjectPublish = {
      organizationId,
      projectId,
      ...dataUpdateProject,
    }

    const res = await dispatch(updateProjectPublishAction(req)).unwrap()
    if (res.error) {
      setShowError(res.error.message)
    } else {
      if (dataUpdateProject.status === TypePublicProject.PUBLIC) {
        openPopupSuccess()
      }
      closePublishSetting()
    }
  }

  const setShowError = (key: string) => {
    openNotification({
      type: TYPE.ERROR,
      key: 'updateProject',
      message: t('notification.somethingBug.titleFirst'),
      description: t('notification.somethingBug.titleSecond'),
    })
  }

  const handleChageDes = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    e.preventDefault()
    setPjDescription(e.target.value)
    setDataUpdateProject({
      ...dataUpdateProject,
      description: e.target.value,
      langCode: TypeLanguage.ENLISH,
    })
    if (e.target.value === projectPublishInfo?.description) {
      delete dataUpdateProject?.description
      delete dataUpdateProject?.langCode
      setDataUpdateProject({
        ...dataUpdateProject,
      })
    }
  }

  const updateCoverImage = (mediaKey: number | string) => {
    if (typeof mediaKey === 'number') {
      delete dataUpdateProject?.thumbnailUrl
      setDataUpdateProject({
        ...dataUpdateProject,
        thumbnailId: mediaKey,
      })
    } else {
      delete dataUpdateProject?.thumbnailId
      setDataUpdateProject({
        ...dataUpdateProject,
        thumbnailUrl: mediaKey,
      })
    }
  }

  const closePublishSetting = () => {
    dispatch(
      projectActions[TYPE_PROJECT.REDUCERS.SET_OPEN_SETTING_PUBLISH](
        !isSettingPublish
      )
    )
  }

  const handleOpenPreview = () => {
    if (projectPublishInfo && projectPublishInfo.projectPublishUrl) {
      window.open(projectPublishInfo.projectPublishUrl)
    }
  }

  const handleRedirectToSelectPackage = useCallback(() => {
    helper.redirectToContact()
  }, [])

  const handleCloseAlertViewOverLimit = () => {
    setIsViewOverLimit(false)
  }

  const handleRedirectToContact = () => {
    helper.redirectToContact()
  }

  return (
    <>
      <div className="bg-blue-700 flex flex-col h-full top-0 right-0 transform w-80 overflow-y-auto overflow-x-hidden absolute z-20">
        <div>
          <div className="flex justify-between items-center text-white pt-4 pb-2 pl-4 pr-1.5">
            <span className="text-xl font-semibold truncate">
              {t('projectSetting.publishSetting')}
            </span>
          </div>
          <div className="py-2 px-4">
            <div className="-mx-2">
              <Select
                value={typePublic}
                onChange={handleChangePublic}
                options={optionsPublic}
                isLabelIcon={true}
              />
              {/* {typePublic &&
              !Array.isArray(typePublic) &&
              typePublic.value === 'private' && (
                <button className="px-2 py-1.5 flex items-center leading-5 text-xs text-blue-300">
                  <div className="w-5 h-5 flex-shrink-0 flex items-center justify-center mr-3">
                    <img src={IconMember} alt="" />
                  </div>
                  {t('projectSetting.editMember')}
                </button>
              )} */}
              {/* <Select
                value={typeTicket}
                onChange={handleChangeTicket}
                options={optionsTicket}
                isLabelIcon={true}
              /> */}
              {/* {typeTicket &&
                !Array.isArray(typeTicket) &&
                typeTicket.value === 'paidContents' && (
                  <div className="flex justify-between py-1">
                    <Input
                      icon={<img src={IconMoney} alt="" />}
                      type="number"
                      defaultValue={1000}
                    />
                    <Select
                      value={typeMoney}
                      onChange={handleChangeMoney}
                      options={optionsMoney}
                      classNameValue="leading-5 !text-white__op-500"
                      classNameSelect="!w-auto flex items-center justify-center"
                    />
                  </div>
                )} */}
            </div>
          </div>
          {typePublic &&
            !Array.isArray(typePublic) &&
            typePublic.value !== 'private' && (
              <div className="px-4">
                <hr className="border-white__op-100" />
                <div className="py-2">
                  <div className="py-1.5 text-xs font-semibold leading-5 text-white__op-600">
                    {t('projectSetting.publicUrl')}
                  </div>
                  <div className="w-full py-1.5 flex items-center">
                    <div className="w-5 h-5 mr-3 flex items-center justify-center shrink-0">
                      <img src={IconLink} alt="" />
                    </div>
                    <button
                      className="text-sm block w-[244px] text-white__op-900 leading-5 font-lato break-words text-left disabled:text-white__op-400"
                      onClick={handleOpenPreview}
                      disabled={isUpdate}
                    >
                      {projectPublishInfo?.projectPublishUrl}
                    </button>
                  </div>
                  {/* <button className="py-1.5 flex items-center leading-5 text-xs text-blue-300 capitalize">
                  <Icon.ActionIcon
                    icon={<img src={IconCsDomain} alt="" />}
                    customClass="mr-3"
                  />
                  {t('projectSetting.customDomain')}
                </button> */}
                </div>
              </div>
            )}

          <hr className="border-white__op-100 mx-4" />
          <div className="py-2 px-4">
            <div className="text-xs py-1.5 font-semibold leading-5 text-white__op-600 flex justify-between items-center">
              <div className="w-full mr-auto">
                {t('projectSetting.overview')}
              </div>
              {/* <Select
              value={typeLanguage}
              onChange={handleChangeLanguage}
              options={optionsLanguage}
              classNameValue="!text-xs !leading-5"
              classNameSelect="!w-auto -mr-2"
              isDisabled
            /> */}
            </div>
            <div className="py-1.5 -mx-2">
              <textarea
                id="pjDescription"
                rows={4}
                className="w-full text-sm py-1.5 px-2 leading-5 text-white__op-900 placeholder:text-white__op-500 bg-white__op-50 rounded-lg focus:outline focus:outline-2 focus:outline-blue-500 font-lato resize-none"
                placeholder={t('projectSetting.plDesProject')}
                onChange={handleChageDes}
                value={pjDescription}
              />
            </div>
          </div>
          <hr className="border-white__op-100 mx-4" />
          <div className="py-2 px-4">
            <div className="text-xs py-1.5 font-semibold leading-5 text-white__op-600">
              {t('projectSetting.coverImage')}
            </div>

            {projectPublishInfo && (
              <ThumbProjectComponent
                projectThumb={projectPublishInfo.thumbnailUrl}
                updateProjectThumb={updateCoverImage}
              />
            )}
          </div>
        </div>

        <div className="px-3 mt-auto mb-2.5">
          <Button.Normal
            className={classNames('font-semibold', {
              '!text-red-300': dataUpdateProject,
            })}
            color="ghost"
            title={
              !dataUpdateProject
                ? t('common.cancel')
                : t('projectSetting.discardChange')
            }
            onClick={closePublishSetting}
          />
          {dataUpdateProject && (
            <Button.Normal
              className="!text-blue-600 font-semibold !bg-blue-50"
              color="ghost"
              title={
                dataUpdateProject.status === TypePublicProject.PUBLIC
                  ? t('projectSetting.saveAndPub')
                  : t('common.save')
              }
              onClick={handleUpdateProjectPublish}
            />
          )}
        </div>
      </div>
      {isViewOverLimit && (
        <PopupConfirm
          title={t('popup.alertViewOverLimit.title')}
          visible={isViewOverLimit}
          messageFirst={t('popup.alertViewOverLimit.messageFirst')}
          okLabel={t('common.upgrade')}
          cancelLabel={t('common.cancel')}
          handleCancel={handleCloseAlertViewOverLimit}
          handleOk={handleRedirectToContact}
        />
      )}
    </>
  )
}

export default ProjectSettingComponent
