import React, { useCallback, useState } from 'react'
import { useAppDispatch, useAppSelector } from '@stores/hook'
import { RootState } from '@stores/store'
import MediaLibPopUp from '../mediaLibPopUp'
import {
  actions as sceneActions,
  IDataTypeEdit,
  TYPE_SETTING,
} from '@stores/scene/scene.reducer'
import { TYPE_SCENE } from '@stores/scene/scene.type'
import ListScenePopup from '../listScenePopup/ListScenePopup'
import MarkerSettingComponent from './markerSetting/MarkerSettingComponent'
import SceneSettingComponent from './sceneSetting/SceneSettingComponent'
import LayerSettingComponent from './LayerSettingComponent'
import SpaceSettingComponent from './spaceSetting/SpaceSettingComponent'
import { IRequestUpdateProject, IResponseProjectInfo } from '@models/project'
import { updateProjectAction } from '@stores/project/project.action'
import { useTranslation } from 'react-i18next'
import { openNotification } from '@utils/notification'
import { TYPE } from '@models/common'
import InfoTagSettingComponent from './infoTagSetting/InfoTagSettingComponent'
import {
  IInfoTagResponse,
  IMarkerResponse,
  IResponseSceneInfo,
} from '@models/sceneSetting'
import ProjectSettingComponent from './projectSetting/ProjectSettingComponent'
import PopupConfirm from '@components/common/PopupConfirm'
import IconSuccess from '@assets/icons/sceneSetting/success.svg'
import Input from '@components/common/Input'
import IconCopy from '@assets/icons/sceneSetting/copy.svg'
import { MODE_OF_MEDIA_LIB_POPUP } from '@models/media'

interface IPropsType {
  dataTypeEdit: IDataTypeEdit
}
const RightMenuComponent: React.FC<IPropsType> = ({ dataTypeEdit }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const {
    projectInfo,
    projectPublishInfo,
    isSettingPublish,
    modeOfMediaLibPopup,
  } = useAppSelector((state: RootState) => state.project)
  const { isShowListScenePopup } = useAppSelector(
    (state: RootState) => state.scene
  )
  const [isShowPublishSuccess, setIsShowPublishSuccess] =
    useState<boolean>(false)

  const handleCloseListSCenePopup = () => {
    dispatch(
      sceneActions[TYPE_SCENE.REDUCERS.SET_STATUS_LIST_SCENE_POPUP](false)
    )
  }

  const updateProject = async (
    projectId: number,
    data: IRequestUpdateProject
  ) => {
    const res = await dispatch(
      updateProjectAction({ projectId, updateProjectInput: data })
    ).unwrap()
    if (res.error) {
      openNotification({
        type: TYPE.ERROR,
        key: 'updateProject',
        message: t('notification.somethingBug.titleFirst'),
        description: t('notification.somethingBug.titleSecond'),
      })
    } else {
      return res.data as IResponseProjectInfo
    }
  }

  const renderInfoSetting = useCallback(() => {
    if (dataTypeEdit) {
      const typeEdit = dataTypeEdit.isTypeEdit
      const data = dataTypeEdit.data
      switch (typeEdit) {
        case TYPE_SETTING.LAYER:
          return data && <LayerSettingComponent updateProject={updateProject} />
        case TYPE_SETTING.SPACE:
          return data && <SpaceSettingComponent updateProject={updateProject} />
        case TYPE_SETTING.SCENE:
          return (
            data && (
              <SceneSettingComponent
                updateProject={updateProject}
                sceneInfo={data as IResponseSceneInfo}
              />
            )
          )
        case TYPE_SETTING.MARKER:
          return (
            data && (
              <MarkerSettingComponent markerInfo={data as IMarkerResponse} />
            )
          )
        case TYPE_SETTING.INFO_TAG:
          return (
            data && (
              <InfoTagSettingComponent dataInfoTag={data as IInfoTagResponse} />
            )
          )
        default:
          break
      }
    }
  }, [dataTypeEdit, projectInfo])

  const handleRedirectToPreviewPalyer = () => {
    if (projectPublishInfo && projectPublishInfo.projectPublishUrl) {
      const { projectPublishUrl } = projectPublishInfo
      window.open(projectPublishUrl)
    }
  }

  const closePublishSetting = () => {
    setIsShowPublishSuccess(false)
  }

  const openPopupSuccess = useCallback(() => {
    setIsShowPublishSuccess(true)
  }, [])

  const copyLink = () => {
    if (projectPublishInfo && projectPublishInfo.projectPublishUrl) {
      navigator.clipboard.writeText(projectPublishInfo.projectPublishUrl)
    }
  }

  return (
    <React.Fragment>
      <div className="w-60 flex-shrink-0 relative">
        {modeOfMediaLibPopup !== MODE_OF_MEDIA_LIB_POPUP.OFF && (
          <MediaLibPopUp />
        )}
        {isShowListScenePopup && (
          <ListScenePopup
            open={isShowListScenePopup}
            close={handleCloseListSCenePopup}
          />
        )}
        {renderInfoSetting()}
        {isSettingPublish && (
          <ProjectSettingComponent openPopupSuccess={openPopupSuccess} />
        )}
      </div>
      {isShowPublishSuccess && (
        <PopupConfirm
          visible={isShowPublishSuccess}
          okLabel={t('popup.publishSuccess.okLabel')}
          cancelLabel={t('popup.publishSuccess.cancelLabel')}
          handleCancel={handleRedirectToPreviewPalyer}
          handleOk={closePublishSetting}
        >
          <div className="pt-6">
            <div className="h-12 w-12 mb-3.5 flex items-center justify-center">
              <img src={IconSuccess} alt="" />
            </div>
            <div className="font-semibold text-xl mb-4">
              {projectInfo?.projectName}{' '}
              {t('popup.publishSuccess.hasPublished')}
            </div>
            <div className="font-lato leading-6 mb-6">
              {t('popup.publishSuccess.des')}
            </div>
            <Input
              className="text-base !leading-7"
              icon={<img src={IconCopy} onClick={copyLink} alt="" />}
              iconPosition="right"
              color="ghost"
              defaultValue={projectPublishInfo?.projectPublishUrl}
              disabled
            />
          </div>
        </PopupConfirm>
      )}
    </React.Fragment>
  )
}

export default React.memo(RightMenuComponent)
