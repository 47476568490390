import React, { ReactNode, useEffect, useRef } from 'react'
import classNames from 'classnames'

type PopupPlacement = 'top' | 'left' | 'right' | 'bottom'

type PopupTriger = 'hover' | 'click'
interface PopupProps {
  open: boolean
  close: () => void
  children?: React.ReactNode
  triger?: PopupTriger
  content: ReactNode
  claassNamePopup?: string
  position?: PopupPlacement
}
const Popup: React.FC<PopupProps> = (props) => {
  const { children, content, open, close, claassNamePopup, position } = props
  const ref = useRef(null)
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        ref.current &&
        !(ref.current as Element).contains(event.target as Node)
      ) {
        close()
      }
    }
    document.addEventListener('click', (e) => handleClickOutside(e), true)
    return () => {
      document.removeEventListener('click', (e) => handleClickOutside(e), true)
    }
  }, [ref])
  const renderPlacement = () => {
    switch (position) {
      case 'right':
        return 'right-0 top-full'
      case 'left':
        return 'left-0 top-full'
      case 'bottom':
        return 'top-full'
      case 'top':
        return 'bottom-full'
      default:
        return '-right-0 top-full'
    }
  }
  return (
    <div className="relative inline-flex">
      {children}
      <div
        className={classNames(
          'w-auto absolute z-10 bg-gray-700 py-2 shadow-dark__popup rounded-lg',
          renderPlacement(),
          { hidden: !open },
          claassNamePopup
        )}
        ref={ref}
      >
        {content}
      </div>
    </div>
  )
}

export default Popup
