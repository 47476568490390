import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { S3MultipleUploader } from '../../../api/s3'
import { TYPE, TYPE_ERROR } from '../../../models/common'
import {
  TYPE_FILE,
  TYPE_MEDIA,
  IRequestUploadMedia,
  MODE_OF_MEDIA_LIB_POPUP,
  IMedia,
  TYPE_STATUS_UPLOAD,
} from '../../../models/media'
import { useAppDispatch, useAppSelector } from '../../../store/hook'
import { uploadMediaAction } from '../../../store/project/project.action'
import { actions as projectActions } from '../../../store/project/project.reducer'
import { RootState } from '../../../store/store'
import { openNotification } from '../../../utils/notification'
import useOnClickOutside from '../../common/hooks'
import DefaultUploadMedia from './DefaultUploadMedia'
import ListMedias from './ListMedias'
import UploadMediaButton from './UploadMediaButton'
import { TYPE_PROJECT } from '../../../store/project/project.type'
import { TYPE_SETTING } from '@stores/scene/scene.reducer'
import { IResponseSceneInfo } from '@models/sceneSetting'
import { IOnProgressFnEvent } from '@models/s3'
import { mediaLibHelper } from '@utils/helper/mediaLib'
import { useStorage } from '../../../hooks/useStorage'

const MediaLibPopUp: React.FC = () => {
  const mediaPopupRef = useRef(null)
  const inputFileUploadRef: React.LegacyRef<HTMLInputElement> = useRef(null)
  const [filteredMedias, setFilteredMedias] = useState<IMedia[]>([])

  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const {
    projectInfo,
    listMedia,
    mediaFolders,
    selectedFolderInMediaLibPopup,
    modeOfMediaLibPopup,
  } = useAppSelector((state: RootState) => state.project)
  const { positionPopupAsset, dataTypeEdit, refButtonSelectMedia } =
    useAppSelector((state: RootState) => state.scene)
  const { userProfile } = useAppSelector((state: RootState) => state.profile)
  const { updateCurrentStorage } = useStorage()
  const acceptedFileTypes = useMemo(
    () =>
      [
        TYPE_FILE.VIDEOS,
        TYPE_FILE.IMAGES,
        TYPE_FILE.AUDIO,
        TYPE_FILE.FILES,
      ].join(', '),
    []
  )

  const mediaTypes = useMemo(() => {
    switch (modeOfMediaLibPopup) {
      case MODE_OF_MEDIA_LIB_POPUP.FILE_3D:
        return [TYPE_MEDIA.IMAGE360, TYPE_MEDIA.VIDEO360]
      case MODE_OF_MEDIA_LIB_POPUP.BGM:
        return [TYPE_MEDIA.AUDIO]
      case MODE_OF_MEDIA_LIB_POPUP.IMAGE:
        return [TYPE_MEDIA.IMAGE]
      case MODE_OF_MEDIA_LIB_POPUP.OTHER_FILE_3D:
        return [
          TYPE_MEDIA.IMAGE,
          TYPE_MEDIA.VIDEO,
          TYPE_MEDIA.AUDIO,
          TYPE_MEDIA.PDF,
        ]
      default:
        return []
    }
  }, [modeOfMediaLibPopup])

  useEffect(() => {
    const mediaElement = document.querySelector('#media') as Element
    if (modeOfMediaLibPopup !== MODE_OF_MEDIA_LIB_POPUP.OFF) {
      showMediaPopup(mediaElement)
    } else {
      hideMediaPopup(mediaElement)
      dispatch(
        projectActions[
          TYPE_PROJECT.REDUCERS.SET_SELECTED_FOLDER_IN_MEDIA_LIB_POPUP
        ](null)
      )
    }
  }, [modeOfMediaLibPopup])

  useEffect(() => {
    const filteredMedias = listMedia.filter((media) =>
      mediaTypes.includes(media.mediaType)
    )
    setFilteredMedias(filteredMedias)
  }, [listMedia, modeOfMediaLibPopup])

  useOnClickOutside(
    mediaPopupRef,
    () => {
      if (
        dataTypeEdit.isTypeEdit === TYPE_SETTING.SCENE &&
        !(dataTypeEdit.data as IResponseSceneInfo).contextUrl &&
        refButtonSelectMedia === 'contextMediaOfScene'
      ) {
        return
      }
      dispatch(
        projectActions[TYPE_PROJECT.REDUCERS.SET_MODE_OF_MEDIA_LIB_POPUP](
          MODE_OF_MEDIA_LIB_POPUP.OFF
        )
      )
      dispatch(projectActions[TYPE_PROJECT.REDUCERS.SET_MEDIA_SELECT](null))
    },
    refButtonSelectMedia
  )

  const showMediaPopup = (element: Element) => {
    element.classList.remove('w-0')
    element.classList.remove('opacity-0')
    element.classList.add('w-[280px]')

    setTimeout(() => {
      element.classList.add('opacity-100')
    }, 100)
  }

  const hideMediaPopup = (element: Element) => {
    element.classList.remove('w-[280px]')
    element.classList.remove('opacity-100')
    element.classList.add('w-0')

    setTimeout(() => {
      element.classList.add('opacity-0')
    }, 100)
  }

  const uploadMediasToS3 = useCallback(
    async (files: FileList, projectId: number) => {
      for (const item of Array.from(files)) {
        const uploader = new S3MultipleUploader({
          file: item,
          projectId,
          is360: true,
        })
        const resSignedUrl = await uploader.initialize()
        if (resSignedUrl.data) {
          const { key, bucket } = uploader.getFileInfo()
          if (!key || !bucket) {
            throw new Error('Key or bucket invalid')
          }

          const is360 = modeOfMediaLibPopup === MODE_OF_MEDIA_LIB_POPUP.FILE_3D
          const mediaType = mediaLibHelper.detectMediaType(item.type, is360)
          const media: IMedia = {
            key,
            mediaType,
            name: item.name,
            status: TYPE_STATUS_UPLOAD.UPLOADING,
            progress: 0,
          }
          dispatch(
            projectActions[TYPE_PROJECT.REDUCERS.ADD_MEDIA]({
              projectId,
              folderId: selectedFolderInMediaLibPopup,
              media,
            })
          )
          uploader.onProgress((event: IOnProgressFnEvent) => {
            dispatch(
              projectActions[TYPE_PROJECT.REDUCERS.UPDATE_MEDIA]({
                folderId: selectedFolderInMediaLibPopup,
                media: {
                  ...media,
                  progress: event.percentage,
                },
              })
            )
          })
          await uploader.start()

          await createRecordsOfMedias({
            projectId,
            fileInput: {
              name: item.name,
              mediaType,
              folderId: selectedFolderInMediaLibPopup,
              key,
              bucket,
            },
          })

          dispatch(
            projectActions[
              TYPE_PROJECT.REDUCERS.REMOVE_UPLOADING_MEDIAS_GLOBAL
            ]({
              projectId,
              media,
              folderId: selectedFolderInMediaLibPopup,
            })
          )
        } else {
          setShowErrorUpload(resSignedUrl.error.message)
        }
      }
    },
    [selectedFolderInMediaLibPopup, modeOfMediaLibPopup]
  )

  const setShowErrorUpload = (key: string) => {
    updateCurrentStorage()
    switch (key) {
      case TYPE_ERROR.RESOURCE_IS_LOCKED:
        if (userProfile) {
          dispatch(
            projectActions[TYPE_PROJECT.REDUCERS.SET_OPEN_ALERT_OVER_SPACE_S3](
              true
            )
          )
          break
        }
      case TYPE_ERROR.PUBLISH_PROJECT_STORAGE_OVER_LIMIT:
        dispatch(
          projectActions[TYPE_PROJECT.REDUCERS.SET_IS_PRJ_STORAGE_OVER_LIMIT](
            true
          )
        )
        break
      case TYPE_ERROR.ORGANIZATION_STORAGE_OVER_LIMIT:
        dispatch(
          projectActions[TYPE_PROJECT.REDUCERS.SET_IS_ORG_STORAGE_OVER_LIMIT](
            true
          )
        )
        break

      default:
        throw new Error()
    }
  }

  const createRecordsOfMedias = async (data: IRequestUploadMedia) => {
    const res = await dispatch(uploadMediaAction(data)).unwrap()
    if (res.error) {
      setShowErrorUpload(res.error.message)
    }

    if (res.data && mediaTypes.includes(res.data.mediaType)) {
      const id = res.data.resourceId
      const type = res.data.mediaType
      const url = res.data.url
      dispatch(
        projectActions[TYPE_PROJECT.REDUCERS.SET_MEDIA_SELECT]({
          id,
          type,
          url,
        })
      )
    }
  }

  const openFileUploadWindow = useCallback(() => {
    inputFileUploadRef.current?.click()
  }, [])

  const handleUploadMedias = useCallback(
    async (files: FileList) => {
      const projectId = projectInfo?.projectId
      if (!projectId) {
        throw new Error('ProjectId is invalid')
      }

      try {
        await uploadMediasToS3(files, projectId)
      } catch (error) {
        openNotification({
          type: TYPE.ERROR,
          key: 'uploadMedia',
          message: t('notification.somethingBug.titleFirst'),
          description: t('notification.somethingBug.titleSecond'),
        })
      }
    },
    [selectedFolderInMediaLibPopup, mediaTypes]
  )

  return (
    <div
      id="media"
      className="w-0 h-[480px] rounded-2xl mr-1 bg-gray-800 shadow-dark__popup opacity-0 overflow-hidden absolute"
      ref={mediaPopupRef}
      style={{ top: positionPopupAsset.top, right: positionPopupAsset.right }}
    >
      <div className="pt-3 px-4 flex justify-between items-center mb-3 h-9">
        <div className="text-white text-base leading-6 font-semibold">
          {modeOfMediaLibPopup === MODE_OF_MEDIA_LIB_POPUP.FILE_3D
            ? t('common.meidaPopupTitle')
            : t('common.assetTitle')}
        </div>
        <UploadMediaButton openFileUploadWindow={openFileUploadWindow} />
        <input
          type="file"
          ref={inputFileUploadRef}
          accept={acceptedFileTypes}
          onChange={(e) => {
            if (!e.target.files) {
              return
            }
            handleUploadMedias(e.target.files)
            e.target.value = ''
          }}
          hidden
        />
      </div>
      {filteredMedias?.length || mediaFolders?.length ? (
        <ListMedias medias={filteredMedias} mediaTypes={mediaTypes} />
      ) : (
        <DefaultUploadMedia openFileUploadWindow={openFileUploadWindow} />
      )}
    </div>
  )
}

export default React.memo(MediaLibPopUp)
