import { Form, Formik, FormikHelpers } from 'formik'
import React, { useState } from 'react'
import * as Yup from 'yup'

import IconLogo from '../../assets/icons/auth/logo.svg'
import InputField from '../../components/common/InputField'
import { TYPE } from '../../models/common'
import { useAppDispatch, useAppSelector } from '../../store/hook'
import { RootState } from '../../store/store'
import { actions as authActions } from '../../store/auth/auth.reducer'
import { TYPES_AUTH } from '../../store/auth/auth.type'
import { createProfileAction } from '../../store/auth/auth.action'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { openNotification } from '../../utils/notification'
import { ERROR_AUTH } from '../../models/auth'
import Button from '../../components/common/Button'
import { VALID_PASSWORD_REGEX, PATHNAME, LANGUAGE } from '@constants/common'
import IconWhereness from '@assets/icons/common/whereness-icon.svg'
import IconCheck from '@assets/icons/common/check-icon.svg'
import classNames from 'classnames'
import i18next from 'i18next'

interface IValuesSignUp {
  email: string
  password: string
  confirmPassword: string
}
const SignUp: React.FC = (): React.ReactElement => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const lang = i18next.language
  const { emailAutofill } = useAppSelector((state: RootState) => state.auth)
  const [isCheckPolicy, setIsCheckPolicy] = useState<boolean>(false)

  const SignUpSchema = Yup.object().shape({
    password: Yup.string()
      .min(6, t('validate.tooShort', { name: t('common.password'), length: 6 }))
      .matches(
        VALID_PASSWORD_REGEX,
        t('validate.invalidFormat', {
          name: t('common.password').toLowerCase(),
        })
      )
      .required(
        t('validate.required', { name: t('common.password').toLowerCase() })
      ),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], t('validate.passwordNotMatch'))
      .required(t('validate.confirmPasswordRequired')),
    email: Yup.string()
      .email(
        t('validate.invalidFormat', {
          name: t('common.emailAddress').toLowerCase(),
        })
      )
      .required(
        t('validate.required', { name: t('common.email').toLowerCase() })
      ),
  })

  const handleSignUp = async (
    values: IValuesSignUp,
    actions: FormikHelpers<IValuesSignUp>
  ) => {
    const { setSubmitting } = actions
    const { email, password } = values
    setSubmitting(true)
    try {
      dispatch(authActions[TYPES_AUTH.REDUCERS.SET_EMAIL_AUTOFILL](email))
      const result = await dispatch(
        createProfileAction({ email, password })
      ).unwrap()
      if (!result.error) {
        navigate(PATHNAME.SIGN_IN)
      } else {
        openNotification({
          type: TYPE.ERROR,
          key: 'createProfile',
          message: t('notification.somethingBug.titleFirst'),
          description: t('notification.somethingBug.titleSecond'),
        })
      }
      setSubmitting(false)
    } catch {
      setSubmitting(false)
      openNotification({
        type: TYPE.ERROR,
        key: 'createProfile',
        message: t('notification.somethingBug.titleFirst'),
        description: t('notification.somethingBug.titleSecond'),
      })
    }
  }

  const handleBlurEmail = async (email: string) => {
    if (email !== emailAutofill) {
      try {
        dispatch(authActions[TYPES_AUTH.REDUCERS.SET_EMAIL_AUTOFILL](email))
        const result = await dispatch(createProfileAction({ email })).unwrap()
        if (result.error) {
          const error = result.error
          if (error.message === ERROR_AUTH.EMAIL_ALREADY_EXISTS) {
            navigate(PATHNAME.SIGN_IN)
          }
        }
      } catch ({}) {
        openNotification({
          type: TYPE.ERROR,
          key: 'createProfile',
          message: t('notification.somethingBug.titleFirst'),
          description: t('notification.somethingBug.titleSecond'),
        })
      }
    }
  }

  const genLinkPolicy = () => {
    // const currentLanguage = navigator.language
    // if (currentLanguage === LANGUAGE.JA) {
    //   return `https://hello.whereness.io/ja/privacy-policy`
    // }
    // return `https://hello.whereness.io/en/privacy-policy`
    return 'https://hello.whereness.io/ja/privacy-policy'
  }

  const genLinkTermOfService = () => {
    // const currentLanguage = navigator.language
    // if (currentLanguage === LANGUAGE.JA) {
    //   return 'https://hello.whereness.io/ja/terms-of-service'
    // }
    // return 'https://hello.whereness.io/en/terms-of-service'
    return 'https://hello.whereness.io/ja/terms-of-service'
  }

  return (
    <React.Fragment>
      <div className="mb-4 pb-2.5">
        <div className="flex justify-center items-center pt-3.5 py-3">
          <img src={IconLogo} alt="" className="mr-1.5" />
          <img src={IconWhereness} alt="" />
        </div>
        <div className="text-base leading-7 text-center text-white__op-600 font-lato">
          {t('auth.signUp.createTile')}
        </div>
      </div>

      <div className="flex flex-col items-center justify-center z-10">
        <Formik
          initialValues={{
            email: emailAutofill || '',
            password: '',
            confirmPassword: '',
          }}
          validationSchema={SignUpSchema}
          onSubmit={handleSignUp}
        >
          {({ isSubmitting, dirty, isValid, values }) => (
            <Form
              className="mb-4 w-full"
              autoComplete="off"
              role="presentation"
            >
              <InputField
                name="email"
                placeholder={t('common.email')}
                type="text"
                labelWrap
                required
                onBlur={() => handleBlurEmail(values.email)}
                isTransition
              />
              <InputField
                name="password"
                placeholder={t('common.password')}
                type="password"
                labelWrap
                required
                // autoComplete="new-password"
                isTransition
              />
              <InputField
                name="confirmPassword"
                placeholder={t('common.confirmPassword')}
                type="password"
                labelWrap
                required
                isTransition
              />
              <div className="text-xs text-white__op-900 text-left py-2 font-lato">
                {t('auth.signUp.validPassword')}
              </div>
              <div className="text-white__op-500 text-xs leading-5 text-center py-2 px-[2.375rem] font-lato flex items-center justify-center">
                <button
                  className="p-3.5"
                  onClick={() => setIsCheckPolicy(!isCheckPolicy)}
                  type="button"
                >
                  <div
                    className={classNames(
                      'w-5 h-5 rounded-lg border-1.5 flex items-center justify-center',
                      {
                        'bg-blue-500 border-blue-500': isCheckPolicy,
                        'border-blue-300': !isCheckPolicy,
                      }
                    )}
                  >
                    {isCheckPolicy && <img src={IconCheck} alt="" />}
                  </div>
                </button>

                {lang === LANGUAGE.JA ? (
                  <div>
                    <a
                      className="underline underline-offset-1 hover:text-blue-300"
                      href={genLinkTermOfService()}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {t('auth.signUp.termOfUse')}
                    </a>
                    <span>・</span>
                    <a
                      className="underline underline-offset-1 hover:text-blue-300"
                      href={genLinkPolicy()}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {t('auth.signUp.privacy')}
                    </a>
                    <span>{t('auth.signUp.agreePolicy')}</span>
                  </div>
                ) : (
                  <div>
                    <span>{t('auth.signUp.agreePolicy')} </span>
                    <a
                      className="underline underline-offset-1 hover:text-blue-300"
                      href={genLinkTermOfService()}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {t('auth.signUp.termOfUse')}
                    </a>
                    <span> and </span>
                    <a
                      className="underline underline-offset-1 hover:text-blue-300"
                      href={genLinkPolicy()}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {t('auth.signUp.privacy')}
                    </a>
                  </div>
                )}

                {/* <a
                  className="hover:text-blue-300"
                  href={genLinkPolicy()}
                  target="_blank"
                  rel="noreferrer"
                >
                  {t('auth.signUp.agreePolicy')}
                </a> */}
              </div>

              <Button.Normal
                type="submit"
                className="!py-3 font-semibold text-sm"
                title={t('auth.signUp.createNewAccount')}
                disabled={!isCheckPolicy || isSubmitting || !dirty || !isValid}
                isTransition
              />
            </Form>
          )}
        </Formik>
      </div>
    </React.Fragment>
  )
}

export default SignUp
