import React from 'react'
import moment from 'moment'
import classNames from 'classnames'
import { useAppSelector } from '@stores/hook'
import { RootState } from '@stores/store'
import Icon from '../common/Icon'
import { IProject } from '@models/project'
import DefaultImage from '@assets/images/project-thumb-default.svg'
import DefaultImage2 from '@assets/images/project-thumb-default-2.svg'
import IconMore from '@assets/icons/common/more-icon.svg'
import { helper } from '@utils/helper/common'
import { PROJECT_SIZE_TYPE } from '@constants/plan'
import { ProjectPublishStatusName } from '@models/organization'
import { useTranslation } from 'react-i18next'
import { LANGUAGE } from '@constants/common'
import i18next from 'i18next'

interface IPropsType {
  projectEdit: IProject | null
  handleOpenMoreSettingProject: (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    indexOpen: number,
    project: IProject
  ) => void
  handleGetInfoProject: (projectId: number) => void
}
const ThumModeComponent: React.FC<IPropsType> = (props): React.ReactElement => {
  const lang = i18next.language
  const { projectEdit, handleGetInfoProject, handleOpenMoreSettingProject } =
    props
  const { t } = useTranslation()
  const { listProject } = useAppSelector((state: RootState) => state.project)
  return (
    <div className="grid grid-cols-3 search-grid-3xl:grid-cols-4 search-grid-4xl:grid-cols-5 h-full">
      {listProject &&
        listProject.projects &&
        listProject.projects.map((project, index) => (
          <div
            className="p-4 hover:bg-white__op-50 rounded-2xl cursor-pointer group"
            key={index}
            onClick={() => handleGetInfoProject(project.projectId)}
          >
            <div
              className="w-full h-40 rounded-lg overflow-hidden relative bg-gray-700
            "
            >
              {project.projectImageUrl ? (
                <img
                  src={project.projectImageUrl}
                  className="w-full h-full"
                  alt=""
                />
              ) : (
                <>
                  <img
                    src={DefaultImage}
                    className="absolute w-full h-full inset-0 mix-blend-multiply object-cover"
                    alt=""
                  />
                  <img
                    src={DefaultImage2}
                    className="absolute w-full h-full inset-0 mix-blend-soft-light object-cover"
                    alt=""
                  />
                </>
              )}
            </div>
            <div className="mt-3 flex justify-between items-center">
              <div className="min-w-0 flex-initial">
                <h1 className="font-semibold text-sm text-white__op-900 mb-1 truncate max-w-[12rem]">
                  {project.projectName}
                </h1>
                <div className="w-full min-w-0 inline-flex flex-wrap text-xs leading-5 text-white__op-500 font-lato break-words">
                  <div className="whitespace-normal w-full break-all">
                    {moment(project.lastEditedAt).format(
                      lang === LANGUAGE.JA ? 'YYYY年MM月DD日' : 'YYYY/MM/DD'
                    )}{' '}
                    <span>{project.lastEditedBy}</span>
                    <span>・</span>
                    <span>
                      {helper.formatBytes(
                        Number(project.currentProjectStorage),
                        2,
                        PROJECT_SIZE_TYPE.GB
                      )}
                    </span>
                  </div>
                </div>
              </div>
              <div className="hidden group-hover:!block" id={`arrow${index}`}>
                <Icon.ButtonIcon
                  icon={<img src={IconMore} alt="" />}
                  size="xs"
                  onClick={(e) =>
                    handleOpenMoreSettingProject(e, index, project)
                  }
                  customClass={classNames('focus:bg-transparent', {
                    '!bg-blue-700':
                      projectEdit?.projectId === project.projectId,
                    'hover:bg-white__op-50':
                      projectEdit?.projectId !== project.projectId,
                  })}
                />
              </div>
              {project.publishStatus === ProjectPublishStatusName.PUBLISH && (
                <div className="px-3 py-[2px] text-xs leading-5 bg-white__op-50 rounded-full whitespace-nowrap text-white__op-600 block group-hover:hidden font-lato">
                  {t('common.public')}
                </div>
              )}
            </div>
          </div>
        ))}
    </div>
  )
}

export default ThumModeComponent
