import { USER_KEY, USER_PROFILE } from '@constants/auth'
import { IResponseUserProfile } from '@models/profile'
import Cookies from 'js-cookie'
import { IUser } from '../models/auth'

export const getTokenCookie = (name: string) => {
  const data = Cookies.get(name)
  if (data) {
    return data
  } else {
    return null
  }
}

export const setTokenCookie = (name: string, value: string) => {
  return Cookies.set(name, value, { domain: process.env.DOMAIN })
}

export const removeLocalCookie = (name: string) => {
  Cookies.remove(name)
}

//user
export const getUserCookie = () => {
  const user = Cookies.get(USER_KEY)
  if (user) {
    const userParse: IUser = JSON.parse(user)
    return userParse
  } else {
    return null
  }
}

export const getUserProfileCookie = () => {
  const user = Cookies.get(USER_PROFILE)
  if (user) {
    const userParse: IResponseUserProfile = JSON.parse(user)
    return userParse
  } else {
    return null
  }
}

export const setUserCookie = (value: IUser) => {
  return Cookies.set(USER_KEY, JSON.stringify(value))
}

export const setLocalCookie = (key: string, value: string) => {
  return Cookies.set(key, value)
}

export const getLocalCookie = (key: string) => {
  const data = Cookies.get(key)
  if (data) {
    return JSON.parse(data)
  }
  return null
}

export const removeAllTokenCookie = () => {
  const listCookie = Cookies.get()
  for (const cookie in listCookie) {
    Cookies.remove(cookie, { domain: process.env.DOMAIN })
  }
  Cookies.remove(USER_KEY)
  Cookies.remove(USER_PROFILE)
}
