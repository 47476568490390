import { gql } from '@apollo/client'

export const GET_LIST_PROJECT = gql`
  query getListProject($organizationId: String!, $pager: PagerInput) {
    getListProject(organizationId: $organizationId, pager: $pager) {
      message
      statusCode
      data {
        projects {
          projectId
          projectName
          projectRole
          description
          publishStatus
          lastEditedAt
          lastEditedBy
          projectImageUrl
          currentProjectStorage
        }
        pager {
          total
          take
          skip
          hasNext
          hasPrevious
        }
      }
      error {
        message
        errorCode
      }
    }
  }
`

export const GET_LIST_PROJECT_STORAGE = gql`
  query getListProjectStorage($organizationId: String!, $pager: PagerInput) {
    getListProject(organizationId: $organizationId, pager: $pager) {
      message
      statusCode
      data {
        projects {
          projectId
          currentProjectStorage
        }
      }
      error {
        message
        errorCode
      }
    }
  }
`

export const GET_PROJECT_INFO = gql`
  query getProjectProfile($projectId: Int!) {
    getProjectProfile(projectId: $projectId) {
      statusCode
      message
      data {
        projectId
        projectName
        langCode
        lastEditedAt
        defaultSceneSet {
          layerId
          spaceId
          sceneId
        }
        projectPreviewUrl
        version
      }
      error {
        errorCode
        message
      }
    }
  }
`

export const GET_LIST_MEDIA = gql`
  query getResources($input: GetResourcesInput!) {
    getResources(input: $input) {
      statusCode
      message
      data {
        folderId
        data {
          resourceId
          url
          key
          thumbnail
          name
          mediaType
          size
          status
          progress
          uploadedBy {
            fullName
          }
          createdAt
        }
        pager {
          total
          skip
          take
          hasNext
          hasPrevious
        }
      }
      error {
        errorCode
        message
      }
    }
  }
`

export const GET_PROJECT_PULISH = gql`
  query getProjectPublish($input: GetProjectPublishInput!) {
    getProjectPublish(input: $input) {
      message
      statusCode
      data {
        projectId
        langCode
        description
        thumbnailId
        thumbnailUrl
        projectPublishUrl
        status
        ticketType
        ticketPrice
      }
      error {
        errorCode
        message
      }
    }
  }
`

export const AVAILABLE_STREAM = gql`
  query availableStream {
    availableStream {
      message
      statusCode
      data {
        availableStream
      }
    }
  }
`
