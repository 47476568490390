import React, { useCallback, useMemo } from 'react'
import Item from './Item'
import DisabledItem from './DisabledItem'
import { Option, Options as ListOption } from './Type'
import GroupItem from './GroupItem'

interface OptionsProps {
  list: ListOption
  noOptionsMessage: string
  text: string
  isMultiple: boolean
  value: Option | Option[] | null
}

const Options: React.FC<OptionsProps> = ({
  list,
  noOptionsMessage,
  text,
  isMultiple,
  value,
}) => {
  const filterByText = useCallback(() => {
    let result = list.map((item) => {
      if ('options' in item) {
        return {
          label: item.label,
          options: item.options,
        }
      }
      return item
    })

    result = result.filter((item) => {
      if ('options' in item) {
        return item.options.length > 0
      }
      return item
    })

    return result
  }, [text, list])

  const removeValues = useCallback(
    (array: ListOption) => {
      if (!isMultiple) {
        return array
      }

      if (Array.isArray(value)) {
        const valueId = value.map((item) => item.value)

        const filterItem = (item: Option) => !valueId.includes(item.value)

        let newArray = array.map((item) => {
          if ('options' in item) {
            return {
              label: item.label,
              options: item.options.filter(filterItem),
            }
          }
          return item
        })

        newArray = newArray.filter((item) => {
          if ('options' in item) {
            return item.options.length > 0
          } else {
            return filterItem(item)
          }
        })

        return newArray
      }
      return array
    },
    [isMultiple, value]
  )

  const filterResult = useMemo(() => {
    return removeValues(filterByText())
  }, [filterByText, removeValues])

  return (
    // eslint-disable-next-line jsx-a11y/aria-role
    <div role="options" className="max-h-72 overflow-y-auto scrollbar-hide">
      {filterResult.map((item, index) => (
        <React.Fragment key={index}>
          {'options' in item ? (
            <>
              <GroupItem item={item} />
              {index + 1 < filterResult.length && <hr className="my-1" />}
            </>
          ) : (
            <Item item={item} />
          )}
        </React.Fragment>
      ))}

      {filterResult.length === 0 && (
        <DisabledItem>{noOptionsMessage}</DisabledItem>
      )}
    </div>
  )
}

export default Options
