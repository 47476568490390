import React, { useCallback, useEffect, useRef, useState } from 'react'
import { ChevronIcon, ChevronIconDis, CloseIcon } from './Icons'
import useOnClickOutside from '../hooks'
import Options from './Options'
import { Option, Options as ListOption } from './Type'
import SelectProvider from './SelectProvider'
import classNames from 'classnames'

export type IValueSelected = Option | Option[] | null
type InputTypeColor = 'transparent' | 'ghost'
interface SelectProps {
  options: ListOption
  value: IValueSelected
  onChange: (value: IValueSelected) => void
  placeholder?: string
  isMultiple?: boolean
  isClearable?: boolean
  isSearchable?: boolean
  isDisabled?: boolean
  menuIsOpen?: boolean
  searchInputPlaceholder?: string
  noOptionsMessage?: string
  isLabelIcon?: boolean
  classNameValue?: string
  classNameSelect?: string
  classNameControl?: string
  color?: InputTypeColor
}

const Select: React.FC<SelectProps> = ({
  options = [],
  value = null,
  onChange,
  placeholder = 'Select...',
  isMultiple = false,
  isClearable = false,
  isDisabled = false,
  menuIsOpen = false,
  noOptionsMessage = 'No options found',
  isLabelIcon,
  classNameValue,
  classNameSelect,
  classNameControl,
  color = 'transparent',
}) => {
  const [open, setOpen] = useState<boolean>(menuIsOpen)
  const [list, setList] = useState<ListOption>(options)
  const [inputValue] = useState<string>('')
  const [isPosBottom, setIsPosBottom] = useState<boolean>(true)
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    setList(
      options.map((item) => {
        if ('options' in item) {
          return {
            label: item.label,
            options: item.options,
          }
        } else {
          return item
        }
      })
    )
  }, [options])

  const toggle = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (!isDisabled) {
        const pageY = e.pageY
        const clientHeight = e.currentTarget.clientHeight
        if (pageY + clientHeight > 280) {
          setIsPosBottom(false)
        }
        setOpen(!open)
      }
    },
    [isDisabled, open]
  )

  const closeDropDown = useCallback(() => {
    if (open) setOpen(false)
  }, [open])

  useOnClickOutside(ref, () => {
    closeDropDown()
  })

  const handleValueChange = useCallback(
    (selected: Option) => {
      function update() {
        if (!isMultiple && !Array.isArray(value)) {
          closeDropDown()
          onChange(selected)
        }

        if (isMultiple && (Array.isArray(value) || value === null)) {
          onChange(value === null ? [selected] : [...value, selected])
        }
      }

      if (selected !== value) {
        update()
      }
    },
    [closeDropDown, isMultiple, onChange, value]
  )

  const clearValue = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation()
      onChange(null)
    },
    [onChange]
  )

  const removeItem = useCallback(
    (e: React.MouseEvent<HTMLDivElement>, item: Option) => {
      if (isMultiple && Array.isArray(value) && value.length) {
        e.stopPropagation()
        const result = value.filter((current) => item.value !== current.value)
        onChange(result.length ? result : null)
      }
    },
    [isMultiple, onChange, value]
  )

  return (
    <SelectProvider value={value} handleValueChange={handleValueChange}>
      <div className={classNames('relative w-full', classNameSelect)} ref={ref}>
        <button
          tabIndex={0}
          aria-expanded={open}
          onClick={toggle}
          className={classNames(
            'flex w-full items-center justify-between text-sm text-gray-500 py-1.5 px-2 rounded-lg cursor-pointer outline outline-2 outline-transparent',
            classNameControl,
            {
              'bg-transparent hover:bg-white__op-50': color === 'transparent',
              'bg-white__op-50': color === 'ghost',
            },
            {
              '!outline-blue-500': open,
            }
          )}
        >
          <div className="flex items-center w-full">
            {!isMultiple ? (
              <>
                {isLabelIcon ? (
                  <>
                    {value && !Array.isArray(value) ? value.label : placeholder}
                  </>
                ) : (
                  <span
                    className={classNames(
                      'w-full truncate select-none text-sm leading-6 text-white__op-900 font-normal cursor-pointer font-lato text-left',
                      { 'text-white__op-300': isDisabled },
                      classNameValue
                    )}
                  >
                    {value && !Array.isArray(value) ? value.label : placeholder}
                  </span>
                )}
              </>
            ) : (
              <>
                {value === null && placeholder}
                {Array.isArray(value) &&
                  value.map((item, index) => (
                    <div
                      className={`bg-gray-200 border rounded-sm flex space-x-1${
                        isDisabled ? 'border-gray-500 px-1' : ' pl-1'
                      }`}
                      key={index}
                    >
                      <p className="text-gray-600 truncate cursor-default select-none">
                        {item.label}
                      </p>
                      {!isDisabled && (
                        <div
                          onClick={(e) => removeItem(e, item)}
                          className={`flex items-center px-1 cursor-pointer rounded-r-sm hover:bg-red-200 hover:text-red-600`}
                        >
                          <CloseIcon className="w-3 h-3 mt-0.5" />
                        </div>
                      )}
                    </div>
                  ))}
              </>
            )}
          </div>

          {isClearable && !isDisabled && value !== null && (
            <div className="px-1.5 cursor-pointer" onClick={clearValue}>
              <CloseIcon className={'w-5 h-5 p-0.5'} />
            </div>
          )}
          <div className="w-5 h-5 flex-shrink-0 flex items-center justify-center">
            {isDisabled ? (
              <ChevronIconDis className={`transition duration-300`} />
            ) : (
              <ChevronIcon className={`transition duration-300`} />
            )}
          </div>
        </button>

        {open && !isDisabled && (
          <div
            tabIndex={-1}
            className={classNames(
              'absolute z-10 w-full bg-gray-700 shadow-dark__select rounded-lg py-2 px-0',
              {
                'top-full mt-1': isPosBottom,
                'bottom-full mb-1': !isPosBottom,
              }
            )}
          >
            <Options
              list={list}
              noOptionsMessage={noOptionsMessage}
              text={inputValue}
              isMultiple={isMultiple}
              value={value}
            />
          </div>
        )}
      </div>
    </SelectProvider>
  )
}

export default Select
