import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import _ from 'lodash'
import { useAppDispatch, useAppSelector } from '@stores/hook'
import {
  actions as sceneActions,
  TYPE_SETTING,
  TYPE_TOOL_SCENE_SETTING,
} from '@stores/scene/scene.reducer'
import { TYPE_SCENE } from '@stores/scene/scene.type'
import { RootState } from '@stores/store'
import Tooltip from '@components/common/Tooltip'
import Icon from '@components/common/Icon'
import MoreAccountSettingPopup from '@components/popups/MoreAccountSettingPopup'
import { actions as projectAction } from '@stores/project/project.reducer'
import { TYPE_PROJECT } from '@stores/project/project.type'
import {
  IDefaultSceneSet,
  IRequestUpdateProject,
  TypeFilterProject,
} from '@models/project'
import { PATHNAME } from '@constants/common'
import { sceneSettingHelper } from '@utils/helper/sceneSetting'
import {
  getSceneAction,
  getSpaceAndSceneAction,
  rollbackVersionAction,
} from '@stores/scene/scene.action'
import { openNotification } from '@utils/notification'
import { TYPE } from '@models/common'
import {
  IRequestGetScene,
  IRequestGetSpaceAndScene,
  IResponseSceneInfo,
  TypeActionUndoRedo,
  TYPE_RESOURCE_OF_PROJECT,
} from '@models/sceneSetting'
//icon
import IconLogo from '@assets/icons/auth/logo.svg'
import IconUndo from '@assets/icons/sceneSetting/undo.svg'
import IconRedo from '@assets/icons/sceneSetting/redo.svg'
import IconMove from '@assets/icons/sceneSetting/move.svg'
import IconMarker from '@assets/icons/sceneSetting/marker.svg'
import IconInfoTag from '@assets/icons/sceneSetting/infoTag.svg'
// import IconComment from '@assets/icons/sceneSetting/comment.svg'
import IconPlay from '@assets/icons/sceneSetting/play.svg'
// import IconShare from '@assets/icons/sceneSetting/share.svg'
import AvatarDefault from '@assets/images/default-avt.svg'
import IconUndoDis from '@assets/icons/sceneSetting/undo-dis.svg'
import IconRedoDis from '@assets/icons/sceneSetting/redo-dis.svg'
import { helper } from '@utils/helper/common'
import SpinComponent from '@components/common/SpinComponent'
import { ID_PROJECT_KEY } from '@constants/sceneSetting'
import { updateProjectAction } from '@stores/project/project.action'

const ToolNavigationComponent: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const {
    toolSceneSetting,
    dataSpaceAndScene,
    disabledUndoRedo,
    contextCodec,
  } = useAppSelector((state: RootState) => state.scene)
  const { user } = useAppSelector((state: RootState) => state.auth)
  const { userProfile } = useAppSelector((state: RootState) => state.profile)
  const { projectInfo } = useAppSelector((state: RootState) => state.project)
  const [isOpenSetting, setIsOpenSetting] = useState<boolean>(false)
  const [isLoadingUndoRedo, setIsLoadingUndoRedo] = useState<boolean>(false)

  const handleNavigateToDashboard = () => {
    dispatch(
      projectAction[TYPE_PROJECT.REDUCERS.SET_TYPE_FILTER_LIST_PROJECT](
        TypeFilterProject.RECENT
      )
    )
    dispatch(sceneActions[TYPE_SCENE.REDUCERS.SET_CLEAR_DATA]([]))
    navigate(PATHNAME.DASHBOARD)
  }

  const handleSetTypeOfTool = (type: number) => {
    if (toolSceneSetting === type) {
      dispatch(
        sceneActions[TYPE_SCENE.REDUCERS.SET_TOOL_SCENE_SETTING](
          TYPE_TOOL_SCENE_SETTING.OFF
        )
      )
      return
    }
    dispatch(sceneActions[TYPE_SCENE.REDUCERS.SET_TOOL_SCENE_SETTING](type))
  }

  const handleSetDefaultTool = () => {
    if (toolSceneSetting !== TYPE_TOOL_SCENE_SETTING.OFF) {
      dispatch(
        sceneActions[TYPE_SCENE.REDUCERS.SET_TOOL_SCENE_SETTING](
          TYPE_TOOL_SCENE_SETTING.OFF
        )
      )
    }
  }

  const openPreviewProject = () => {
    if (projectInfo && projectInfo.projectPreviewUrl) {
      const { projectId, projectPreviewUrl } = projectInfo
      window.open(`${projectPreviewUrl}?projectId=${projectId}`)
    }
  }

  const handleUndo = useCallback(
    async (isUndo: boolean) => {
      setIsLoadingUndoRedo(true)
      if (projectInfo) {
        let dataUndo = undefined
        if (isUndo) {
          dataUndo = sceneSettingHelper.getUndoVersionFromStorage(
            projectInfo.projectId
          )
        } else {
          dataUndo = sceneSettingHelper.getRedoVersionFromStorage(
            projectInfo.projectId
          )
        }

        if (dataUndo && projectInfo) {
          const { projectId } = projectInfo
          const { undoVer, newlistProjectVer } = dataUndo
          const { version, data } = undoVer
          const { listLayer, idScene } = data
          try {
            const res = await dispatch(
              rollbackVersionAction({ projectId, version })
            ).unwrap()
            if (res.error) {
              openNotification({
                type: TYPE.ERROR,
                key: 'rollbackVersion',
                message: t('notification.somethingBug.titleFirst'),
                description: t('notification.somethingBug.titleSecond'),
              })
            } else {
              const { isDisRedo, isDisUndo } =
                sceneSettingHelper.updateCurrentVersionToStorage(
                  newlistProjectVer,
                  projectId,
                  version,
                  res.data.version
                )

              dispatch(
                sceneActions[TYPE_SCENE.REDUCERS.SET_DISABLED_UNDO_REDO]({
                  isRedo: isDisRedo,
                  isUndo: isDisUndo,
                })
              )
              if (listLayer) {
                dispatch(
                  sceneActions[TYPE_SCENE.REDUCERS.SET_LIST_SPACE_AND_SCENE](
                    listLayer
                  )
                )
                switch (data.action) {
                  case TypeActionUndoRedo.DELETE:
                    const dataLSS =
                      sceneSettingHelper.getLayerSpaceSceneFromSceneId(
                        listLayer,
                        data.id
                      )
                    if (dataLSS) {
                      dispatch(
                        sceneActions[TYPE_SCENE.REDUCERS.SET_ACTIVE_VIEW]({
                          type: TYPE_RESOURCE_OF_PROJECT.SCENE,
                          layer: dataLSS.layer,
                          space: dataLSS.space,
                          scene: dataLSS.scene,
                        })
                      )
                      const resGetScene = await getScene(data.id)
                      if (resGetScene) {
                        const dataTypeEdit = {
                          isTypeEdit: TYPE_SETTING.SCENE,
                          data: resGetScene,
                        }
                        dispatch(
                          sceneActions[
                            TYPE_SCENE.REDUCERS.SET_TYPE_OF_RESOURCE
                          ](dataTypeEdit)
                        )
                      }
                    }
                    break
                  default:
                    switch (data.type) {
                      case TYPE_SETTING.LAYER:
                        const layerActive = _.find(
                          listLayer,
                          (layer) => layer.info.id === data.id
                        )
                        if (layerActive) {
                          dispatch(
                            sceneActions[TYPE_SCENE.REDUCERS.SET_ACTIVE_VIEW]({
                              type: TYPE_RESOURCE_OF_PROJECT.LAYER,
                              layer: layerActive,
                            })
                          )
                          const dataEdit = {
                            isTypeEdit: data.type,
                            data: layerActive,
                          }
                          dispatch(
                            sceneActions[
                              TYPE_SCENE.REDUCERS.SET_TYPE_OF_RESOURCE
                            ](dataEdit)
                          )
                        }
                        break
                      case TYPE_SETTING.SPACE:
                        const { layer, space } =
                          sceneSettingHelper.getLayerSpaceFromSpaceId(
                            listLayer,
                            data.id
                          )
                        if (layer && space) {
                          dispatch(
                            sceneActions[TYPE_SCENE.REDUCERS.SET_ACTIVE_VIEW]({
                              type: TYPE_RESOURCE_OF_PROJECT.SPACE,
                              layer,
                              space,
                            })
                          )
                          const dataEdit = {
                            isTypeEdit: data.type,
                            data: space,
                          }
                          dispatch(
                            sceneActions[
                              TYPE_SCENE.REDUCERS.SET_TYPE_OF_RESOURCE
                            ](dataEdit)
                          )
                        }
                        break
                      case TYPE_SETTING.SCENE:
                        const dataLSS =
                          sceneSettingHelper.getLayerSpaceSceneFromSceneId(
                            listLayer,
                            data.id
                          )
                        if (
                          dataLSS &&
                          dataLSS.layer &&
                          dataLSS.space &&
                          dataLSS.scene
                        ) {
                          dispatch(
                            sceneActions[TYPE_SCENE.REDUCERS.SET_ACTIVE_VIEW]({
                              type: TYPE_RESOURCE_OF_PROJECT.SCENE,
                              layer: dataLSS.layer,
                              space: dataLSS.space,
                              scene: dataLSS.scene,
                            })
                          )
                          const resGetScene = await getScene(
                            dataLSS.scene.info.id
                          )
                          if (resGetScene) {
                            const dataTypeEdit = {
                              isTypeEdit: TYPE_SETTING.SCENE,
                              data: resGetScene,
                            }
                            dispatch(
                              sceneActions[
                                TYPE_SCENE.REDUCERS.SET_TYPE_OF_RESOURCE
                              ](dataTypeEdit)
                            )
                            if (
                              data.id !== projectInfo.defaultSceneSet.sceneId
                            ) {
                              await updateProject(projectInfo.projectId, {
                                defaultSceneSet: {
                                  layerId: dataLSS.layer.info.id,
                                  spaceId: dataLSS.space.info.id,
                                  sceneId: dataLSS.scene.info.id,
                                },
                              })
                            }
                          }
                        }
                        break
                      default:
                        break
                    }
                    break
                }
              }
              if (idScene) {
                const resGetScene = await getScene(idScene)
                if (resGetScene) {
                  if (data.action === TypeActionUndoRedo.DELETE) {
                    dispatch(
                      sceneActions[TYPE_SCENE.REDUCERS.SET_TYPE_OF_RESOURCE]({
                        isTypeEdit: TYPE_SETTING.PROJECT,
                        data: null,
                      })
                    )
                  } else {
                    const { layer, space, scene } =
                      sceneSettingHelper.getLayerSpaceSceneFromSceneId(
                        dataSpaceAndScene ?? [],
                        idScene
                      )
                    if (layer && space && scene) {
                      dispatch(
                        sceneActions[TYPE_SCENE.REDUCERS.SET_ACTIVE_VIEW]({
                          type: TYPE_RESOURCE_OF_PROJECT.SCENE,
                          layer,
                          space,
                          scene,
                        })
                      )
                      if (
                        scene.info.id !== projectInfo.defaultSceneSet.sceneId
                      ) {
                        await updateProject(projectInfo.projectId, {
                          defaultSceneSet: {
                            layerId: layer.info.id,
                            spaceId: space.info.id,
                            sceneId: scene.info.id,
                          },
                        })
                      }
                    }
                    switch (data.type) {
                      case TYPE_SETTING.INFO_TAG:
                        const dataEditInfoTag = {
                          isTypeEdit: data.type,
                          data: _.find(resGetScene.infoTags, { id: data.id }),
                        }
                        dispatch(
                          sceneActions[
                            TYPE_SCENE.REDUCERS.SET_TYPE_OF_RESOURCE
                          ](dataEditInfoTag)
                        )
                        break
                      case TYPE_SETTING.MARKER:
                        const dataEditMarker = {
                          isTypeEdit: data.type,
                          data: _.find(resGetScene.markers, { id: data.id }),
                        }
                        dispatch(
                          sceneActions[
                            TYPE_SCENE.REDUCERS.SET_TYPE_OF_RESOURCE
                          ](dataEditMarker)
                        )
                        break
                      default:
                        break
                    }
                  }
                }
              }
              if (!listLayer && !idScene && data.rootId) {
                await handleGetSpaceAndScene(data.rootId)
              }
            }
          } catch (error) {
            openNotification({
              type: TYPE.ERROR,
              key: 'rollbackVersion',
              message: t('notification.somethingBug.titleFirst'),
              description: t('notification.somethingBug.titleSecond'),
            })
          }
        }
      }
      setIsLoadingUndoRedo(false)
    },
    [projectInfo]
  )

  useEffect(() => {
    const projectId = sessionStorage.getItem(ID_PROJECT_KEY)
    if (projectId) {
      const { isDisRedo, isDisUndo } = sceneSettingHelper.checkCanUndoOrRedo(
        JSON.parse(projectId)
      )
      dispatch(
        sceneActions[TYPE_SCENE.REDUCERS.SET_DISABLED_UNDO_REDO]({
          isRedo: isDisRedo,
          isUndo: isDisUndo,
        })
      )
    }
  }, [handleUndo])

  const updateProject = async (
    projectId: number,
    data: IRequestUpdateProject
  ) => {
    const res = await dispatch(
      updateProjectAction({ projectId, updateProjectInput: data })
    ).unwrap()
    if (res.error) {
      openNotification({
        type: TYPE.ERROR,
        key: 'updateProject',
        message: t('notification.somethingBug.titleFirst'),
        description: t('notification.somethingBug.titleSecond'),
      })
    }
  }

  const getScene = async (sceneId: number) => {
    if (projectInfo) {
      const dataGetScene: IRequestGetScene = {
        projectId: projectInfo.projectId,
        sceneId,
        contextCodec,
      }
      try {
        const res = await dispatch(getSceneAction(dataGetScene)).unwrap()
        if (res.error) {
          openNotification({
            type: TYPE.ERROR,
            key: 'rollbackVersion',
            message: t('notification.somethingBug.titleFirst'),
            description: t('notification.somethingBug.titleSecond'),
          })
        } else {
          return res.data as IResponseSceneInfo
        }
      } catch {
        openNotification({
          type: TYPE.ERROR,
          key: 'rollbackVersion',
          message: t('notification.somethingBug.titleFirst'),
          description: t('notification.somethingBug.titleSecond'),
        })
      }
    }
  }

  const handleGetSpaceAndScene = async (rootId: IDefaultSceneSet) => {
    if (projectInfo && user) {
      const dataGetSpaceAndScene: IRequestGetSpaceAndScene = {
        projectId: projectInfo.projectId,
        organizationId: user.organizationId,
      }
      const res = await dispatch(
        getSpaceAndSceneAction(dataGetSpaceAndScene)
      ).unwrap()
      if (res.data) {
        const { layers, spaces, scenes, orderStructure } = res.data
        const newsListLayer = helper.sortSpaceAndScene(
          layers,
          spaces,
          scenes,
          orderStructure,
          rootId
        )
        dispatch(
          sceneActions[TYPE_SCENE.REDUCERS.SET_LIST_SPACE_AND_SCENE](
            newsListLayer
          )
        )
        const { layer, space, scene } =
          sceneSettingHelper.getLayerSpaceSceneFromSceneId(
            newsListLayer,
            rootId.sceneId
          )
        if (layer && space && scene) {
          dispatch(
            sceneActions[TYPE_SCENE.REDUCERS.SET_ACTIVE_VIEW]({
              type: TYPE_RESOURCE_OF_PROJECT.SCENE,
              layer,
              space,
              scene,
            })
          )
          const resGetScene = await getScene(rootId.sceneId)
          if (resGetScene) {
            const dataTypeEdit = {
              isTypeEdit: TYPE_SETTING.SCENE,
              data: resGetScene,
            }
            dispatch(
              sceneActions[TYPE_SCENE.REDUCERS.SET_TYPE_OF_RESOURCE](
                dataTypeEdit
              )
            )
            await updateProject(projectInfo.projectId, {
              defaultSceneSet: {
                layerId: layer.info.id,
                spaceId: space.info.id,
                sceneId: scene.info.id,
              },
            })
          }
        }
      }
    }
  }

  return (
    <>
      <div className="w-16 flex-shrink-0 pt-4 pb-3 bg-gray-900">
        <div className="w-full h-full flex flex-col justify-between items-center">
          <div className="flex flex-col items-center">
            <button className="mb-[26px]" onClick={handleNavigateToDashboard}>
              <img src={IconLogo} alt="" />
            </button>
            <div className="flex items-center flex-col">
              <Tooltip title={t('sceneSetting.sideBar.undo')}>
                <Icon.ButtonIcon
                  icon={
                    <img
                      src={disabledUndoRedo.isUndo ? IconUndoDis : IconUndo}
                      alt=""
                    />
                  }
                  size="xs"
                  onClick={() => handleUndo(true)}
                  disabled={disabledUndoRedo.isUndo}
                />
              </Tooltip>
              <Tooltip title={t('sceneSetting.sideBar.redo')}>
                <Icon.ButtonIcon
                  icon={
                    <img
                      src={disabledUndoRedo.isRedo ? IconRedoDis : IconRedo}
                      alt=""
                    />
                  }
                  size="xs"
                  onClick={() => handleUndo(false)}
                  disabled={disabledUndoRedo.isRedo}
                />
              </Tooltip>
            </div>
          </div>
          <div>
            <div className="flex flex-col">
              <Tooltip
                title={t('sceneSetting.sideBar.select')}
                tooltipClassName="left-[3.75rem]"
              >
                <Icon.ButtonIcon
                  icon={<img src={IconMove} alt="" />}
                  size="sm"
                  isActive={
                    toolSceneSetting !== TYPE_TOOL_SCENE_SETTING.MARKER &&
                    toolSceneSetting !== TYPE_TOOL_SCENE_SETTING.INFO_TAG
                  }
                  onClick={handleSetDefaultTool}
                />
              </Tooltip>
              <Tooltip
                title={t('sceneSetting.sideBar.marker')}
                tooltipClassName="left-[3.75rem]"
              >
                <Icon.ButtonIcon
                  icon={<img src={IconMarker} alt="" />}
                  size="sm"
                  isActive={toolSceneSetting === TYPE_TOOL_SCENE_SETTING.MARKER}
                  onClick={() =>
                    handleSetTypeOfTool(TYPE_TOOL_SCENE_SETTING.MARKER)
                  }
                />
              </Tooltip>
              <Tooltip
                title={t('sceneSetting.sideBar.infoTag')}
                tooltipClassName="left-[3.75rem]"
              >
                <Icon.ButtonIcon
                  icon={<img src={IconInfoTag} alt="" />}
                  size="sm"
                  isActive={
                    toolSceneSetting === TYPE_TOOL_SCENE_SETTING.INFO_TAG
                  }
                  onClick={() =>
                    handleSetTypeOfTool(TYPE_TOOL_SCENE_SETTING.INFO_TAG)
                  }
                />
              </Tooltip>
            </div>
            {/* <hr className="border-white__op-200 my-4 mx-2" />
            <Tooltip
              title={t('sceneSetting.sideBar.comment')}
              tooltipClassName="left-[3.75rem]"
            >
              <Icon.ButtonIcon
                icon={<img src={IconComment} alt="" />}
                size="sm"
              />
            </Tooltip> */}
          </div>
          <div className="flex flex-col items-center">
            <Tooltip
              title={t('sceneSetting.sideBar.play')}
              containerClassName="mb-5"
            >
              <Icon.ButtonIcon
                icon={<img src={IconPlay} alt="" />}
                size="xs"
                onClick={openPreviewProject}
              />
            </Tooltip>
            {/* <Tooltip title={t('sceneSetting.sideBar.share')}>
              <Icon.ButtonIcon
                icon={<img src={IconShare} alt="" />}
                size="xs"
                customClass="mb-5"
              />
            </Tooltip> */}

            <MoreAccountSettingPopup
              open={isOpenSetting}
              close={() => setIsOpenSetting(false)}
              claassNamePopup="!left-0"
            >
              <button
                className="w-8 h-8 rounded-full overflow-hidden"
                onClick={() => setIsOpenSetting(true)}
              >
                <img
                  src={
                    userProfile?.info.avatar
                      ? userProfile.info.avatar
                      : AvatarDefault
                  }
                  className="w-full h-full object-cover"
                  alt=""
                />
              </button>
            </MoreAccountSettingPopup>
          </div>
        </div>
      </div>
      {isLoadingUndoRedo && <SpinComponent />}
    </>
  )
}

export default ToolNavigationComponent
