import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { useAppDispatch, useAppSelector } from '@stores/hook'
import { TYPE_RESOURCE_OF_PROJECT } from '@models/sceneSetting'
import { S3MultipleUploader } from '../../../api/s3'
import { actions as sceneActions } from '@stores/scene/scene.reducer'
import { TYPE_SCENE } from '@stores/scene/scene.type'
import {
  getProjectPublishAction,
  updateProjectAction,
  uploadMediaAction,
} from '@stores/project/project.action'
import {
  IRequestGetProjectPublish,
  IRequestUpdateProject,
  IResponseProjectInfo,
  IWaitingS3Uploader,
} from '@models/project'
import { RootState } from '@stores/store'
import { openNotification } from '@utils/notification'
import { TYPE, TYPE_ERROR } from '@models/common'
import {
  IMedia,
  IProcessUploadFile,
  IRequestUploadMedia,
  TYPE_FILE,
  TYPE_STATUS_UPLOAD,
} from '@models/media'
import ListMedias from './assets'
import UploadMediaButton from '../mediaLibPopUp/UploadMediaButton'
import AddSpaceOrSceneButton from './AddSpaceOrSceneButton'
import { helper } from '@utils/helper/common'
import MoreProjectSetting from './MoreProjectSetting'
import { actions as projectActions } from '@stores/project/project.reducer'
import { TYPE_PROJECT } from '@stores/project/project.type'
import ListLayerComponent from './listSpaceScene'
import { IOnProgressFnEvent } from '@models/s3'
import { mediaLibHelper } from '@utils/helper/mediaLib'
import { sceneSettingHelper } from '@utils/helper/sceneSetting'
import Button from '@components/common/Button'
import PopupConfirm from '@components/common/PopupConfirm'
import { useLocation, useSearchParams } from 'react-router-dom'
import { PATHNAME } from '@constants/common'
import { IResponseUserProfile } from '@models/profile'
import { actions as profileActions } from '@stores/profile/profile.reducer'
import { TYPE_PROFILE } from '@stores/profile/profile.type'
import { PROJECT_SIZE_TYPE } from '@constants/plan'
import {
  DEFAULT_WIDTH_SIDEBAR,
  KEY_QUERY_PLAYER,
} from '@constants/sceneSetting'
import { useStorage } from '../../../hooks/useStorage'

const LeftSideBar: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const location = useLocation()
  const [searchParams] = useSearchParams()
  const { dataSpaceAndScene } = useAppSelector(
    (state: RootState) => state.scene
  )
  const {
    projectInfo,
    selectedFolderInAsset,
    processListFileUpload,
    isSettingPublish,
    isShowAlertOverSpaceS3,
    isPrjStorageOverLimit,
    isOrgStorageOverLimit,
  } = useAppSelector((state: RootState) => state.project)
  const { user } = useAppSelector((state: RootState) => state.auth)
  const { userProfile } = useAppSelector((state: RootState) => state.profile)
  const { updateCurrentStorage } = useStorage()
  const [isViewMedia, setIsViewMedia] = useState<boolean>(false)
  const sidebarRef = useRef(null)
  const waitingS3UploaderRef = useRef<IWaitingS3Uploader>({
    isUploading: false,
    uploaders: [],
  })
  const inputMediaUploadRef: React.LegacyRef<HTMLInputElement> = useRef(null)

  const [isResizing, setIsResizing] = useState<boolean>(false)
  const [sidebarWidth, setSidebarWidth] = useState<number>(
    DEFAULT_WIDTH_SIDEBAR
  )

  useEffect(() => {
    if (projectInfo && dataSpaceAndScene) {
      const { defaultSceneSet } = projectInfo
      const { layerId, spaceId, sceneId } = defaultSceneSet
      const newListLayerCo = helper.openScene(
        dataSpaceAndScene,
        layerId,
        spaceId
      )
      dispatch(
        sceneActions[TYPE_SCENE.REDUCERS.SET_LIST_SPACE_AND_SCENE](
          newListLayerCo
        )
      )
      setActivePreviewScene(sceneId)
    }

    if (
      (location.state &&
        (location.state as { from: string }).from === PATHNAME.DASHBOARD) ||
      searchParams.get(KEY_QUERY_PLAYER)
    ) {
      handleSwitchProjectPublishSetting()
    } else {
      if (isSettingPublish) {
        dispatch(
          projectActions[TYPE_PROJECT.REDUCERS.SET_OPEN_SETTING_PUBLISH](
            !isSettingPublish
          )
        )
      }
    }
  }, [])

  const setActivePreviewScene = async (idScene: number) => {
    const { layer, scene, space } =
      sceneSettingHelper.getLayerSpaceSceneFromSceneId(
        dataSpaceAndScene ?? [],
        idScene
      )
    if (layer && space && scene) {
      dispatch(
        sceneActions[TYPE_SCENE.REDUCERS.SET_ACTIVE_VIEW]({
          type: TYPE_RESOURCE_OF_PROJECT.SCENE,
          layer: layer,
          space: space,
          scene: scene,
        })
      )
    }
  }

  const handleUpdateProject = async (
    projectId: number,
    data: IRequestUpdateProject
  ) => {
    const res = await dispatch(
      updateProjectAction({ projectId, updateProjectInput: data })
    ).unwrap()
    if (res.error) {
      openNotification({
        type: TYPE.ERROR,
        key: 'updateProject',
        message: t('notification.somethingBug.titleFirst'),
        description: t('notification.somethingBug.titleSecond'),
      })
    } else {
      return res.data as IResponseProjectInfo
    }
  }

  const startResizing = React.useCallback(() => {
    setIsResizing(true)
  }, [])

  const stopResizing = React.useCallback(() => {
    setIsResizing(false)
  }, [])

  const resize = React.useCallback(
    (mouseMoveEvent: MouseEvent) => {
      if (isResizing && sidebarRef.current) {
        const width =
          mouseMoveEvent.clientX -
          (sidebarRef.current as HTMLDivElement).getBoundingClientRect().left
        if (width <= 482 && width >= 202) {
          setSidebarWidth(width)
          dispatch(
            sceneActions[TYPE_SCENE.REDUCERS.SET_SIDEBAR_WIDTH](
              mouseMoveEvent.clientX -
                (sidebarRef.current as HTMLDivElement).getBoundingClientRect()
                  .left
            )
          )
        }
      }
    },
    [isResizing]
  )

  useEffect(() => {
    window.addEventListener('mousemove', resize)
    window.addEventListener('mouseup', stopResizing)
    return () => {
      window.removeEventListener('mousemove', resize)
      window.removeEventListener('mouseup', stopResizing)
    }
  }, [resize, stopResizing])

  const createRecordsOfMedias = async (data: IRequestUploadMedia) => {
    const res = await dispatch(uploadMediaAction(data)).unwrap()
    if (res.error) {
      setShowErrorUpload(res.error.message)
    }
    if (res.data) {
      dispatch(
        projectActions[TYPE_PROJECT.REDUCERS.SET_FOLDER_CREATE_NERW_RECORD](
          data.fileInput.folderId
        )
      )
      if (userProfile) {
        const newData: IResponseUserProfile = {
          ...userProfile,
          info: {
            ...userProfile.info,
            currentOrganizationStorage: res.data.currentOrganizationStorage,
          },
        }
        dispatch(
          profileActions[TYPE_PROFILE.REDUCERS.SET_USER_PROFILE](newData)
        )
      }
    }
  }

  const setProcessListProject = (ListFile: FileList) => {
    const listDataFile = Array.from(ListFile).map((file) => {
      return {
        fileName: file.name,
        fileType: file.type,
        status: TYPE_STATUS_UPLOAD.WAITTING,
        lastModified: file.lastModified,
      }
    })
    const dataProcessUpload: IProcessUploadFile = {
      session: processListFileUpload
        ? `session${processListFileUpload.length + 1}`
        : 'session1',
      listDataFile,
    }
    if (processListFileUpload) {
      dispatch(
        projectActions[TYPE_PROJECT.REDUCERS.SET_LIST_FILE_UPLOAD_WAITTING]([
          ...processListFileUpload,
          dataProcessUpload,
        ])
      )
      return dataProcessUpload.session
    }
    dispatch(
      projectActions[TYPE_PROJECT.REDUCERS.SET_LIST_FILE_UPLOAD_WAITTING]([
        dataProcessUpload,
      ])
    )
    return dataProcessUpload.session
  }

  const updateProcessListProject = (
    session: string,
    fileName: string,
    lastModified: number,
    status: TYPE_STATUS_UPLOAD
  ) => {
    dispatch(
      projectActions[TYPE_PROJECT.REDUCERS.SET_FILE_UPLOADING]({
        session,
        dataFile: {
          fileName,
          lastModified,
          status,
        },
      })
    )
  }

  const handleUploadMedias = React.useCallback(
    async (files: FileList) => {
      const newListFile = sceneSettingHelper.getFilesHaveNotLimit(files)
      if (newListFile.length > 0) {
        const sesstion = setProcessListProject(newListFile)
        const projectId = projectInfo?.projectId
        if (!projectId) {
          throw new Error('ProjectId is invalid')
        }
        let sessCom = null
        try {
          const { session } = await initUploader(
            newListFile,
            projectId,
            sesstion
          )
          sessCom = session
        } catch (error) {
          console.log({ error })
          openNotification({
            type: TYPE.ERROR,
            key: 'uploadMedia',
            message: t('notification.somethingBug.titleFirst'),
            description: t('notification.somethingBug.titleSecond'),
          })
        } finally {
          dispatch(
            projectActions[TYPE_PROJECT.REDUCERS.SET_FILE_COMPLETE](sessCom)
          )
        }
      }
    },
    [selectedFolderInAsset, processListFileUpload, projectInfo]
  )

  const initUploader = React.useCallback(
    async (files: FileList, projectId: number, session: string) => {
      const uploaders = []

      for (const item of Array.from(files)) {
        updateProcessListProject(
          session,
          item.name,
          item.lastModified,
          TYPE_STATUS_UPLOAD.UPLOADING
        )
        const uploader = new S3MultipleUploader({
          file: item,
          projectId,
        })

        const resSignedUrl = await uploader.initialize()
        if (resSignedUrl.data) {
          const { key, bucket } = uploader.getFileInfo()
          if (!key || !bucket) {
            throw new Error('Key or bucket invalid')
          }

          const mediaType = mediaLibHelper.detectMediaType(item.type)
          const media: IMedia = {
            key,
            mediaType,
            name: item.name,
            status: TYPE_STATUS_UPLOAD.UPLOADING,
            progress: 0,
          }
          dispatch(
            projectActions[TYPE_PROJECT.REDUCERS.ADD_MEDIA]({
              projectId,
              folderId: selectedFolderInAsset,
              media,
            })
          )
          uploader.onProgress((event: IOnProgressFnEvent) => {
            dispatch(
              projectActions[TYPE_PROJECT.REDUCERS.UPDATE_MEDIA]({
                folderId: selectedFolderInAsset,
                media: {
                  ...media,
                  progress: event.percentage,
                },
              })
            )
          })

          uploaders.push(uploader)
        } else {
          setShowErrorUpload(resSignedUrl.error.message)
        }
      }

      waitingS3UploaderRef.current.uploaders.push(...uploaders)
      await startUploader(projectId, session)

      return { session }
    },
    [selectedFolderInAsset, processListFileUpload]
  )

  const startUploader = async (projectId: number, session: string) => {
    if (waitingS3UploaderRef.current.isUploading) {
      return
    }

    waitingS3UploaderRef.current.isUploading = true
    while (waitingS3UploaderRef.current.uploaders.length) {
      const uploader = waitingS3UploaderRef.current.uploaders.shift()
      await uploader?.start()
      const fileInfo = uploader?.getFileInfo()

      if (
        !fileInfo?.bucket ||
        !fileInfo.file ||
        !fileInfo.key ||
        !(fileInfo.file instanceof File)
      ) {
        throw Error('File is invalid')
      }
      const mediaType = mediaLibHelper.detectMediaType(fileInfo.file.type)

      updateProcessListProject(
        session,
        fileInfo.file.name,
        fileInfo.file.lastModified,
        TYPE_STATUS_UPLOAD.COMPLETE
      )

      await createRecordsOfMedias({
        projectId,
        fileInput: {
          name: fileInfo.file.name,
          mediaType,
          folderId: selectedFolderInAsset,
          key: fileInfo.key,
          bucket: fileInfo.bucket,
        },
      })

      const media: IMedia = {
        key: fileInfo.key,
        mediaType,
        name: fileInfo.file.name,
        status: TYPE_STATUS_UPLOAD.UPLOADING,
        progress: 0,
      }

      dispatch(
        projectActions[TYPE_PROJECT.REDUCERS.REMOVE_UPLOADING_MEDIAS_GLOBAL]({
          projectId,
          media,
          folderId: selectedFolderInAsset,
        })
      )
    }
    waitingS3UploaderRef.current.isUploading = false
  }

  const handleCancelUpload = async (folderId: number | null, media: IMedia) => {
    const projectId = projectInfo?.projectId
    if (!projectId) {
      throw new Error('ProjectId is invalid')
    }

    waitingS3UploaderRef.current.uploaders =
      waitingS3UploaderRef.current.uploaders.filter(
        (uploader) => uploader.getFileInfo().key !== media?.key
      )

    dispatch(
      projectActions[TYPE_PROJECT.REDUCERS.REMOVE_MEDIA]({
        ...media,
        folderId,
      })
    )

    dispatch(
      projectActions[TYPE_PROJECT.REDUCERS.REMOVE_UPLOADING_MEDIAS_GLOBAL]({
        projectId,
        media,
        folderId,
      })
    )
  }

  const setShowErrorUpload = (key: string) => {
    updateCurrentStorage()
    switch (key) {
      case TYPE_ERROR.RESOURCE_IS_LOCKED:
        if (userProfile) {
          dispatch(
            projectActions[TYPE_PROJECT.REDUCERS.SET_OPEN_ALERT_OVER_SPACE_S3](
              true
            )
          )
          break
        }
      case TYPE_ERROR.PUBLISH_PROJECT_STORAGE_OVER_LIMIT:
        dispatch(
          projectActions[TYPE_PROJECT.REDUCERS.SET_IS_PRJ_STORAGE_OVER_LIMIT](
            true
          )
        )
        break
      case TYPE_ERROR.ORGANIZATION_STORAGE_OVER_LIMIT:
        dispatch(
          projectActions[TYPE_PROJECT.REDUCERS.SET_IS_ORG_STORAGE_OVER_LIMIT](
            true
          )
        )
        break

      default:
        throw new Error()
    }
  }

  const acceptedFileTypes = useMemo(
    () =>
      [
        TYPE_FILE.VIDEOS,
        TYPE_FILE.IMAGES,
        TYPE_FILE.AUDIO,
        TYPE_FILE.FILES,
      ].join(', '),
    []
  )

  const openFileUploadWindow = () => {
    inputMediaUploadRef.current?.click()
  }

  const handleSwitchProjectPublishSetting = useCallback(async () => {
    try {
      if (!projectInfo || !user) {
        return
      }
      const req: IRequestGetProjectPublish = {
        organizationId: user.organizationId,
        projectId: projectInfo.projectId,
      }
      const res = await dispatch(getProjectPublishAction(req)).unwrap()
      if (res.data) {
        dispatch(
          projectActions[TYPE_PROJECT.REDUCERS.SET_OPEN_SETTING_PUBLISH](true)
        )
      } else {
        openNotification({
          type: TYPE.ERROR,
          key: 'getProjectPublish',
          message: t('notification.somethingBug.titleFirst'),
          description: t('notification.somethingBug.titleSecond'),
        })
      }
    } catch {
      openNotification({
        type: TYPE.ERROR,
        key: 'getProjectPublish',
        message: t('notification.somethingBug.titleFirst'),
        description: t('notification.somethingBug.titleSecond'),
      })
    }
  }, [projectInfo?.projectId])

  const handleRedirectToSelectPackage = useCallback(() => {
    if (isShowAlertOverSpaceS3) {
      handleCloseAlertTrial()
    }
    if (isOrgStorageOverLimit) {
      handleCloseAlertOrgStorageOverLimit()
    }
    if (isPrjStorageOverLimit) {
      handleCloseAlerPrjStorageOverLimit()
    }
    helper.redirectToContact()
  }, [])

  const handleCloseAlertTrial = useCallback(() => {
    dispatch(
      projectActions[TYPE_PROJECT.REDUCERS.SET_OPEN_ALERT_OVER_SPACE_S3](false)
    )
  }, [])

  const handleCloseAlertOrgStorageOverLimit = useCallback(() => {
    dispatch(
      projectActions[TYPE_PROJECT.REDUCERS.SET_IS_ORG_STORAGE_OVER_LIMIT](false)
    )
  }, [])

  const handleCloseAlerPrjStorageOverLimit = useCallback(() => {
    dispatch(
      projectActions[TYPE_PROJECT.REDUCERS.SET_IS_PRJ_STORAGE_OVER_LIMIT](false)
    )
  }, [])

  const bytesToGB = (bytes: number, numFixed: number) => {
    return helper.formatBytes(bytes, numFixed, PROJECT_SIZE_TYPE.GB)
  }

  return (
    <React.Fragment>
      <div
        className="flex flex-shrink-0 relative"
        ref={sidebarRef}
        style={{ width: sidebarWidth, minWidth: 202, maxWidth: 482 }}
      >
        <div
          className="bg-gray-800 flex flex-col"
          style={{ width: sidebarWidth - 2, minWidth: 200, maxWidth: 480 }}
        >
          <MoreProjectSetting
            handleSwitchProjectPublishSetting={
              handleSwitchProjectPublishSetting
            }
          />
          <div className="flex justify-between pl-2 pr-1 items-center">
            <div className="flex text-white font-semibold text-xs">
              <span
                className={classNames(
                  "relative cursor-pointer leading-5 text-xs text-white__op-500 px-2 pt-3 pb-2 after:content-[''] after:h-[2px] after:w-4 after:absolute after:bottom-0 after:left-1/2 after:-translate-x-1/2 after:bg-transparent hover:after:bg-white__op-50 hover:text-white__op-900",
                  {
                    'after:!bg-blue-500 !text-white__op-900': !isViewMedia,
                  }
                )}
                onClick={() => setIsViewMedia(false)}
              >
                {t('common.spaces')}
              </span>
              <span
                className={classNames(
                  "relative cursor-pointer leading-5 text-xs text-white__op-500 px-2 pt-3 pb-2 after:content-[''] after:h-[2px] after:w-4 after:absolute after:bottom-0 after:left-1/2 after:-translate-x-1/2 after:bg-transparent hover:after:bg-white__op-50 hover:text-white__op-900",
                  {
                    'after:!bg-blue-500 !text-white__op-900': isViewMedia,
                  }
                )}
                onClick={() => setIsViewMedia(true)}
              >
                {t('common.assetTitle')}
              </span>
            </div>

            {isViewMedia ? (
              <UploadMediaButton openFileUploadWindow={openFileUploadWindow} />
            ) : (
              <AddSpaceOrSceneButton
                handleUpdateProject={handleUpdateProject}
              />
            )}
            <input
              type="file"
              ref={inputMediaUploadRef}
              accept={acceptedFileTypes}
              onChange={(e) => {
                if (!e.target.files) {
                  return
                }
                handleUploadMedias(e.target.files)
                e.target.value = ''
              }}
              multiple
              hidden
            />
          </div>

          <hr className="border-black__op-300" />
          <div className="h-full overflow-y-auto">
            {isViewMedia ? (
              <ListMedias handleCancelUpload={handleCancelUpload} />
            ) : (
              dataSpaceAndScene && <ListLayerComponent />
            )}
          </div>
          <div className="px-4 pb-2.5">
            <Button.Normal
              className="!py-1.5 font-semibold"
              color="solid"
              title={t('projectSetting.publishing')}
              onClick={handleSwitchProjectPublishSetting}
            />
          </div>
        </div>
        <div
          className="flex-grow-0 flex-shrink-0 basis-[2px] justify-self-end cursor-col-resize resize-x bg-gray-800 hover:bg-blue-500"
          onMouseDown={startResizing}
        />
      </div>
      {isShowAlertOverSpaceS3 && userProfile && (
        <PopupConfirm
          title={t('popup.alertOverSpaceTrial.title')}
          visible={isShowAlertOverSpaceS3}
          messageFirst={t('popup.alertOverSpaceTrial.storageUse', {
            use: bytesToGB(
              Number(userProfile.info.currentOrganizationStorage),
              2
            ),
            limit: bytesToGB(
              Number(userProfile.info.limitedOrganizationStorage),
              2
            ),
          })}
          messageSecond={t('popup.alertOverSpaceTrial.des')}
          okLabel={t('common.upgrade')}
          cancelLabel={t('common.cancel')}
          handleCancel={handleCloseAlertTrial}
          handleOk={handleRedirectToSelectPackage}
        />
      )}
      {isOrgStorageOverLimit && userProfile && (
        <PopupConfirm
          title={t('popup.alertOrgStorageOverLimit.title')}
          visible={isOrgStorageOverLimit}
          messageFirst={t('popup.alertOrgStorageOverLimit.storageUse', {
            use: bytesToGB(
              Number(userProfile.info.currentOrganizationStorage),
              2
            ),
            limit: bytesToGB(
              Number(userProfile.info.limitedOrganizationStorage),
              2
            ),
          })}
          messageSecond={t('popup.alertOrgStorageOverLimit.des')}
          okLabel={t('common.upgrade')}
          cancelLabel={t('common.cancel')}
          handleCancel={handleCloseAlertOrgStorageOverLimit}
          handleOk={handleRedirectToSelectPackage}
        />
      )}
      {isPrjStorageOverLimit && (
        <PopupConfirm
          title={t('popup.alertPrjStorageOverLimit.title')}
          visible={isPrjStorageOverLimit}
          messageFirst={t('popup.alertPrjStorageOverLimit.messageFirst')}
          okLabel={t('common.selectPubOption')}
          cancelLabel={t('common.cancel')}
          handleCancel={handleCloseAlerPrjStorageOverLimit}
          handleOk={handleRedirectToSelectPackage}
        />
      )}
    </React.Fragment>
  )
}

export default LeftSideBar
