import React, { MouseEvent } from 'react'
import { IMedia, ISelectedMedia } from '@models/media'
import { useAppDispatch, useAppSelector } from '@stores/hook'
import classNames from 'classnames'
import { RootState } from '@stores/store'
import { TYPE_PROJECT } from '@stores/project/project.type'
import { actions } from '@stores/project/project.reducer'

interface PropTypes {
  media: IMedia
  icon: string | undefined
  folderId?: number | null
  handleMediaRightClick: (
    event: MouseEvent<HTMLDivElement, globalThis.MouseEvent>
  ) => void
}

enum EventType {
  dragstart = 'dragstart',
  mouseup = 'mouseup',
}

const NormalMedia: React.FC<PropTypes> = ({
  media,
  icon,
  folderId = null,
  handleMediaRightClick,
}) => {
  const dispatch = useAppDispatch()
  const { selectedMediaInAsset } = useAppSelector(
    (state: RootState) => state.project
  )

  const handleEvent = (event: React.SyntheticEvent<HTMLDivElement>) => {
    switch (event.type) {
      case EventType.mouseup:
        handleSelectedMedia()
        break
      case EventType.dragstart:
        handleDragStart(event as React.DragEvent<HTMLDivElement>)
        break
    }
  }

  const handleSelectedMedia = () => {
    dispatch(
      actions[TYPE_PROJECT.REDUCERS.SET_SELECTED_MEDIA_IN_ASSET]({
        key: media.key,
        folderId,
        mediaType: media.mediaType,
        resourceId: media.resourceId,
      })
    )
  }

  const handleDragStart = (event: React.DragEvent<HTMLDivElement>) => {
    const payload: ISelectedMedia = {
      key: media.key,
      folderId,
      resourceId: media.resourceId,
      mediaType: media.mediaType,
    }
    event.dataTransfer.setData('media', JSON.stringify(payload))
    event.currentTarget.classList.add('dragging')
    event.dataTransfer.effectAllowed = 'copyMove'
  }

  const handleDragEnd = (event: React.DragEvent<HTMLDivElement>) => {
    event.currentTarget.classList.remove('dragging')
  }

  return (
    <div
      draggable={true}
      onDragStart={handleEvent}
      onDragEnd={handleDragEnd}
      className={classNames(
        'flex items-center px-4 py-1 hover:cursor-pointer font-lato text-xs',
        {
          'bg-blue-700': media.key === selectedMediaInAsset?.key,
          'hover:bg-white__op-100': media.key !== selectedMediaInAsset?.key,
        }
      )}
      onContextMenu={(event) => handleMediaRightClick(event)}
      onMouseUp={handleEvent}
    >
      <img
        src={icon}
        className="mr-2 w-8 h-8 rounded-lg flex-shrink-0"
        alt=""
      />
      <span className="truncate text-white__op-800">{media.name}</span>
    </div>
  )
}

export default NormalMedia
