import { createAsyncThunk } from '@reduxjs/toolkit'
import { graphqlApi } from '../../api/graphql'
import { UPDATE_PROFILE } from '../../graphql/auth/mutation'
import { API_TYPE_AUTH } from '../../graphql/auth/type'
import { CHANGE_PASSWORD, DELETE_ACCOUNT } from '../../graphql/profile/mutation'
import {
  GET_SIGNED_URL_UPLOAD_AVATAR,
  GET_STORAGE,
  GET_USER_PROFILE,
} from '../../graphql/profile/query'
import { API_TYPE_PROFILE } from '../../graphql/profile/type'
import { IRequestSignedUrlUploadAvatar } from '../../models/common'
import { IDataEditProfile } from '../../models/profile'
import { TYPE_PROFILE } from './profile.type'

export const changePasswordAction = createAsyncThunk(
  TYPE_PROFILE.ACTIONS.CHANGE_PASSWORD,
  async (data: { password: string; newPassword: string }) => {
    const result = await graphqlApi.mutationRequest(
      CHANGE_PASSWORD,
      API_TYPE_AUTH.MUTATION.CHANGE_PASSWORD,
      data
    )
    return result
  }
)

export const getUserProfileAction = createAsyncThunk(
  TYPE_PROFILE.ACTIONS.GET_USER_PROFILE,
  async () => {
    const result = await graphqlApi.queryRequest(
      GET_USER_PROFILE,
      API_TYPE_PROFILE.QUERY.GET_USER_PROFILE
    )
    return result
  }
)

export const getSignedUrlUploadAvatarAction = createAsyncThunk(
  TYPE_PROFILE.ACTIONS.GET_SIGNED_URL_UPLOAD_AVATAR,
  async (input: IRequestSignedUrlUploadAvatar) => {
    const result = await graphqlApi.queryRequest(
      GET_SIGNED_URL_UPLOAD_AVATAR,
      API_TYPE_PROFILE.QUERY.GET_SIGNED_URL_UPLOAD_AVATAR,
      { input }
    )
    return result
  }
)

export const updateUserProfileAction = createAsyncThunk(
  TYPE_PROFILE.ACTIONS.UPDATE_USER_PROFILE,
  async (data: IDataEditProfile) => {
    const result = await graphqlApi.mutationRequest(
      UPDATE_PROFILE,
      API_TYPE_AUTH.MUTATION.UPDATE_USER_PROFILE,
      data
    )
    return result
  }
)

export const deleteAccountAction = createAsyncThunk(
  TYPE_PROFILE.ACTIONS.DELETE_ACCOUNT,
  async (input: { password: string }) => {
    const result = await graphqlApi.mutationRequest(
      DELETE_ACCOUNT,
      API_TYPE_PROFILE.MUTATION.DELETE_ACCOUNT,
      { input }
    )
    return result
  }
)

export const getStorageAction = createAsyncThunk(
  TYPE_PROFILE.ACTIONS.GET_STORAGE,
  async () => {
    const result = await graphqlApi.queryRequest(
      GET_STORAGE,
      API_TYPE_PROFILE.QUERY.GET_USER_PROFILE
    )
    return result
  }
)
