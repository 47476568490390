export const TYPES_AUTH = {
  REDUCERS: {
    SET_ACCESS_TOKEN: 'setAccessToken',
    SET_REFRESH_TOKEN: 'setRefreshToken',
    SET_REMEMBER_TOKEN: 'setRememberToken',
    SET_USER: 'setUser',
    NEXT_STEP: 'nextStep',
    PREV_STEP: 'prevStep',
    SET_SEND_EMAIL: 'setSendEmail',
    SET_CLEAR_DATA: 'setClearData',
    SET_EDIT_STEP_1: 'setEditStep1',
    SET_EMAIL_AUTOFILL: 'setEmailAutofill',
  },
  ACTIONS: {
    LOGIN: 'auth/login',
    LOGIN_WITH_GOOGLE: 'auth/loginWithGoogle',
    CREATE_PROFILE: 'auth/createProfile',
    GET_SIGNED_URL_UPLOAD_MEDIA: 'auth/getSignedUrlUploadMedia',
    UPLOAD_TO_S3: 'auth/uploadToS3',
    UPDATE_PROFILE: 'auth/updateProfile',
    SELECT_PACKAGE: 'auth/selectPackage',
    CREATE_PAYMENT: 'auth/createPayment',
    SEND_EMAIL_CONFIRM_REGISTER: 'auth/sendEmailConfirmRegister',
    SEND_EMAIL_REGISTER_SUCCESS: 'auth/sendEmailRegisterSuccess',
    FORGOT_PASSWORD: 'auth/forgotPassword',
    RESET_PASSWORD: 'auth/resetPassword',
    REFRESH_TOKEN: 'auth/refreshToken',
    LOGOUT: 'auth/logout',
  },
}
