import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Popup from '@components/common/Popup'
import Icon from '@components/common/Icon'
import { openNotification } from '@utils/notification'
import { useAppDispatch, useAppSelector } from '@stores/hook'
import { RootState } from '@stores/store'
import { createMediaFolderAction } from '@stores/project/project.action'
import { TYPE } from '@models/common'
import Button from '@components/common/Button'
import UploadIcon from '@assets/icons/mediaIcon/upload.svg'
import FolderIcon from '@assets/icons/mediaIcon/folder.svg'
import IconAdd from '@assets/icons/sceneSetting/add.svg'

interface PropTypes {
  openFileUploadWindow: () => void
}

const UploadMediaButton: React.FC<PropTypes> = ({ openFileUploadWindow }) => {
  const { t } = useTranslation()
  const [isOpenAddMediaPopup, setIsOpenAddMediaPopup] = useState(false)
  const { projectInfo, mediaFolders } = useAppSelector(
    (state: RootState) => state.project
  )
  const dispatch = useAppDispatch()

  const handleCreateMediaFolder = useCallback(async () => {
    try {
      const projectId = projectInfo?.projectId
      if (!projectId) {
        throw new Error('ProjectId is invalid')
      }

      const res = await dispatch(
        createMediaFolderAction({
          projectId: projectInfo?.projectId,
          name: `Folder${mediaFolders?.length + 1}`,
        })
      ).unwrap()

      if (res.error) {
        throw new Error(res.error)
      }
    } catch {
      openNotification({
        type: TYPE.ERROR,
        key: 'createMediaFolder',
        message: t('notification.somethingBug.titleFirst'),
        description: t('notification.somethingBug.titleSecond'),
      })
    } finally {
      setIsOpenAddMediaPopup(false)
    }
  }, [mediaFolders?.length])

  return (
    <>
      <Popup
        open={isOpenAddMediaPopup}
        close={() => setIsOpenAddMediaPopup(false)}
        claassNamePopup="w-[150px] mr-2"
        content={
          <div className="flex flex-col font-lato">
            <Button.MenuItem
              icon={<img src={UploadIcon} alt="" />}
              title={t('popup.mediaLib.uploadFiles')}
              onClick={openFileUploadWindow}
            />
            <Button.MenuItem
              icon={<img src={FolderIcon} alt="" />}
              title={t('popup.mediaLib.newFolder')}
              onClick={handleCreateMediaFolder}
            />
          </div>
        }
      >
        <Icon.ButtonIcon
          icon={<img src={IconAdd} alt="" />}
          onClick={() => setIsOpenAddMediaPopup(true)}
        />
      </Popup>
    </>
  )
}

export default UploadMediaButton
