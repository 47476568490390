import React, { useCallback, useEffect, useState } from 'react'
import classNames from 'classnames'

import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { TYPE, TYPE_ERROR } from '../../models/common'
import { TypeFilterProject } from '../../models/project'
import { useAppDispatch, useAppSelector } from '../../store/hook'
import { createProjectAction } from '@stores/project/project.action'
import { actions } from '../../store/project/project.reducer'
import { TYPE_PROJECT } from '../../store/project/project.type'
import { RootState } from '../../store/store'
import { openNotification } from '../../utils/notification'
import Icon from '../common/Icon'
import Input from '../common/Input'
import Popup from '../common/Popup'
import searchIcon from '../../assets/icons/mainLayout/search.svg'
import surfaceListViewIcon from '../../assets/icons/mainLayout/surface_list_view.svg'
import surfaceGridViewIcon from '../../assets/icons/mainLayout/surface_grid_view.svg'
import addIcon from '../../assets/icons/mainLayout/Add.svg'
import dropdownIcon from '../../assets/icons/mainLayout/dropdown.svg'
import IconTune from '../../assets/icons/common/icon-tune.svg'
import { PATHNAME } from '@constants/common'
import PopupConfirm from '@components/common/PopupConfirm'
import { helper } from '@utils/helper/common'
import { localStorageUtils } from '@utils/localStorage'
import { MODE_VIEW_PROJECT } from '@constants/modeViewProject'

const HeaderDashboardComponent: React.FC = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { modeViewProject, typeFilterListProject } = useAppSelector(
    (state: RootState) => state.project
  )
  const { user } = useAppSelector((state: RootState) => state.auth)
  const { userProfile } = useAppSelector((state: RootState) => state.profile)
  const [searchOpen, setSearchOpen] = useState<boolean>(false)
  const [headerTitle, setHeaderTitle] = useState<string | null>(null)
  const [openAddProject, setOpenAddProject] = useState<boolean>(false)
  const [isShowAlertTrial, setIsShowAlertTrial] = useState<boolean>(false)

  const toggleOpen = (): void => {
    if (searchOpen) setSearchOpen(!searchOpen)
  }

  useEffect(() => {
    switch (typeFilterListProject) {
      case TypeFilterProject.RECENT: {
        setHeaderTitle(t('dashboard.header.recent'))
        break
      }
      case TypeFilterProject.SHARED: {
        setHeaderTitle(t('dashboard.header.shared'))
        break
      }
      case TypeFilterProject.YOUR_PROJECT: {
        setHeaderTitle(t('dashboard.header.yourProject'))
        break
      }
      default: {
        break
      }
    }
  }, [typeFilterListProject])

  const handleCreateProject = async () => {
    if (user && user.organizationId) {
      const data = {
        organizationId: user.organizationId,
        projectName: 'New Project',
      }
      try {
        const res = await dispatch(createProjectAction(data)).unwrap()
        if (res.error) {
          if (userProfile?.info.organizationRemainingDays === 0) {
            dispatch(
              actions[TYPE_PROJECT.REDUCERS.SET_OPEN_ALERT_EXPIRED_TRIAL](true)
            )
            return
          }
          if (res.error.message === TYPE_ERROR.RESOURCE_IS_LOCKED) {
            setIsShowAlertTrial(true)
          } else {
            openNotification({
              type: TYPE.ERROR,
              key: 'createProject',
              message: t('notification.somethingBug.titleFirst'),
              description: t('notification.somethingBug.titleSecond'),
            })
          }
        } else {
          setOpenAddProject(false)
          navigate(`${PATHNAME.SCENE_SETTING}/${res.data.projectId}`)
        }
      } catch ({}) {
        openNotification({
          type: TYPE.ERROR,
          key: 'createProject',
          message: t('notification.somethingBug.titleFirst'),
          description: t('notification.somethingBug.titleSecond'),
        })
      }
    }
  }

  const handleRedirectToSelectPackage = useCallback(() => {
    helper.redirectToContact()
  }, [])

  const handleCloseAlertTrial = () => {
    setIsShowAlertTrial(false)
  }

  return (
    <>
      <div className="flex items-center py-3.5">
        {searchOpen ? (
          <div className="w-full mr-4">
            <Input
              id="inputSearch"
              icon={<img src={searchIcon} alt="search icon" />}
              placeholder={t('dashboard.header.searchProject')}
              onClick={(e) => e.stopPropagation()}
              className={classNames(
                'flex items-center bg-white__op-100 !rounded-2xl font-normal font-lato text-base !leading-7 text-white__op-500 caret-white__op-500'
              )}
              onBlur={() => toggleOpen()}
            />
          </div>
        ) : (
          <span
            className={classNames(
              'flex-1 text-white__op-900 text-4xl font-semibold leading-[44px]'
            )}
          >
            {headerTitle}
          </span>
        )}

        <div className="flex items-center">
          <Icon.ButtonIcon
            icon={<img src={surfaceListViewIcon} alt="list view icon" />}
            customClass={classNames('focus:bg-transparent', {
              '!bg-blue-700 !outline-transparent': !modeViewProject,
              'hover:bg-white__op-50': modeViewProject,
            })}
            size="sm"
            onClick={() => {
              if (!modeViewProject) {
                return
              }
              localStorageUtils.setValueToLocalStorage(
                MODE_VIEW_PROJECT.KEY,
                !modeViewProject
              )
              dispatch(
                actions[TYPE_PROJECT.REDUCERS.SET_MODE_VIEW_PROJECT](
                  !modeViewProject
                )
              )
            }}
          />
          <Icon.ButtonIcon
            icon={<img src={surfaceGridViewIcon} alt="grid view icon" />}
            customClass={classNames('focus:bg-transparent', {
              '!bg-blue-700 !outline-transparent': modeViewProject,
              'hover:bg-white__op-50': !modeViewProject,
            })}
            size="sm"
            onClick={() => {
              if (modeViewProject) {
                return
              }
              localStorageUtils.setValueToLocalStorage(
                MODE_VIEW_PROJECT.KEY,
                !modeViewProject
              )
              dispatch(
                actions[TYPE_PROJECT.REDUCERS.SET_MODE_VIEW_PROJECT](
                  !modeViewProject
                )
              )
            }}
          />
          <Popup
            open={openAddProject}
            close={() => setOpenAddProject(false)}
            content={
              <div className="flex flex-col !font-lato">
                <button
                  className="flex items-center justify-between px-4 py-1.5 text-sm leading-5 hover:bg-white__op-50"
                  onClick={() => handleCreateProject()}
                >
                  <div className="text-white__op-900 flex items-center mr-3">
                    <Icon.ActionIcon
                      icon={<img src={IconTune} alt="icon" />}
                      customClass="mr-3"
                    />
                    <div className="whitespace-nowrap capitalize">
                      {t('common.projects')}
                    </div>
                  </div>
                </button>
              </div>
            }
            position="left"
            claassNamePopup="mt-1.5 !w-[12.5rem] !left-4"
          >
            <button
              className="flex items-center ml-4 py-2.5 px-3.5 border-2 border-transparent bg-blue-500 rounded-2xl hover:bg-blue-450 focus-visible:!border-blue-400 focus:border-blue-400 focus:hover:bg-blue-500 focus-visible:outline-0"
              onClick={() => setOpenAddProject(true)}
            >
              <Icon.ActionIcon
                icon={<img src={addIcon} alt="add icon" />}
                customClass="w-6 h-6"
              />
              <span className="text-sm font-semibold text-blue-50 mx-2">
                {t('common.create')}
              </span>
              <Icon.ActionIcon
                icon={<img src={dropdownIcon} alt="dropdown icon" />}
                customClass="w-6 h-6"
              />
            </button>
          </Popup>
        </div>
      </div>
      {isShowAlertTrial && (
        <PopupConfirm
          title={t('popup.alertTrialCreateProject.title')}
          visible={isShowAlertTrial}
          messageSecond={t('popup.alertTrialCreateProject.messageFirst')}
          okLabel={t('common.upgrade')}
          cancelLabel={t('common.cancel')}
          handleCancel={handleCloseAlertTrial}
          handleOk={handleRedirectToSelectPackage}
        />
      )}
    </>
  )
}

export default HeaderDashboardComponent
