import React, { useEffect, useState } from 'react'
import { IMedia, TYPE_MEDIA, TYPE_STATUS_UPLOAD } from '../../../models/media'
import AudioIcon from '../../../assets/icons/mediaIcon/audio.svg'
import PdfIcon from '../../../assets/icons/mediaIcon/pdf.svg'
import { useAppDispatch } from '../../../store/hook'
import { actions as projectActions } from '../../../store/project/project.reducer'
import { TYPE_PROJECT } from '../../../store/project/project.type'
import { openNotification } from '@utils/notification'
import { TYPE } from '@models/common'
import { useTranslation } from 'react-i18next'
import ConvertingMedia from '../leftSidebar/assets/Media/ConvertingMedia'
import UploadingMedia from '../leftSidebar/assets/Media/UploadingMedia'
import { helper } from '../../../utils/helper/common'

interface PropTypes {
  media: IMedia
}

const Media: React.FC<PropTypes> = ({ media }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [icon, setIcon] = useState<string>()

  useEffect(() => {
    getIconSrc()
  }, [media.thumbnail, media.url])

  const getIconSrc = () => {
    switch (media.mediaType) {
      case TYPE_MEDIA.AUDIO:
        setIcon(AudioIcon)
        break
      case TYPE_MEDIA.PDF:
        setIcon(PdfIcon)
        if (!media?.url) {
          return
        }
        helper.genNumPage(media.url, 1).then((thumbnails) => {
          if (thumbnails?.[0]) {
            setIcon(thumbnails?.[0])
          }
        })
        break
      default:
        setIcon(media?.thumbnail)
        break
    }
  }

  const handleSelectAsset = (
    id: number | undefined,
    type: TYPE_MEDIA,
    url?: string
  ) => {
    try {
      if (!id) {
        throw new Error('Id is required')
      }
      dispatch(
        projectActions[TYPE_PROJECT.REDUCERS.SET_MEDIA_SELECT]({
          id,
          type,
          url,
        })
      )
    } catch (error) {
      console.log({ error })
      openNotification({
        type: TYPE.ERROR,
        key: 'uploadMedia',
        message: t('notification.somethingBug.titleFirst'),
        description: t('notification.somethingBug.titleSecond'),
      })
    }
  }

  return media.status === TYPE_STATUS_UPLOAD.UPLOADING ? (
    <UploadingMedia media={media} tooltipPosition={'left'} />
  ) : media.status === TYPE_STATUS_UPLOAD.CONVERTING ? (
    <ConvertingMedia media={media} tooltipPosition={'left'} />
  ) : (
    <div
      className="flex items-center px-4 py-1 hover:cursor-pointer hover:bg-white__op-100 font-lato text-xs"
      onClick={() =>
        handleSelectAsset(media?.resourceId, media.mediaType, media.url)
      }
    >
      <img src={icon} className="mr-2 w-8 h-8 rounded-lg" alt="" />
      <span className="w-52 truncate text-white__op-800">{media.name}</span>
    </div>
  )
}

export default Media
